import eas from 'erpcore/assets/svg/eas.svg';
import bugReport from 'erpcore/assets/svg/bugReport.svg';
import approve from 'erpcore/assets/svg/approve.svg';
import calculatedAmount from 'erpcore/assets/svg/calculatedAmount.svg';
import cancel from 'erpcore/assets/svg/cancel.svg';
import chat from 'erpcore/assets/svg/chat.svg';
import chatAlternative from 'erpcore/assets/svg/chatAlternative.svg';
import easPowered from 'erpcore/assets/svg/easPowered.svg';
import crop from 'erpcore/assets/svg/crop.svg';
import camera from 'erpcore/assets/svg/camera.svg';
import cameraOff from 'erpcore/assets/svg/cameraOff.svg';
import photo from 'erpcore/assets/svg/photo.svg';
import completion from 'erpcore/assets/svg/completion.svg';
import columns from 'erpcore/assets/svg/columns.svg';
import dashboard from 'erpcore/assets/svg/dashboard.svg';
import date from 'erpcore/assets/svg/date.svg';
import dollar from 'erpcore/assets/svg/dollar.svg';
import drag from 'erpcore/assets/svg/drag.svg';
import download from 'erpcore/assets/svg/download.svg';
import globe from 'erpcore/assets/svg/globe.svg';
import hoursBillable from 'erpcore/assets/svg/hoursBillable.svg';
import hoursLogged from 'erpcore/assets/svg/hoursLogged.svg';
import reject from 'erpcore/assets/svg/reject.svg';
import remove from 'erpcore/assets/svg/remove.svg';
import edit from 'erpcore/assets/svg/edit.svg';
import editSquare from 'erpcore/assets/svg/editSquare.svg';
import favorites from 'erpcore/assets/svg/favorites.svg';
import favoritesFull from 'erpcore/assets/svg/favoritesFull.svg';
import filter from 'erpcore/assets/svg/filter.svg';
import flipHorizontal from 'erpcore/assets/svg/flipHorizontal.svg';
import flipVertical from 'erpcore/assets/svg/flipVertical.svg';
import group from 'erpcore/assets/svg/group.svg';
import help from 'erpcore/assets/svg/help.svg';
import link from 'erpcore/assets/svg/link.svg';
import linkExternal from 'erpcore/assets/svg/linkExternal.svg';
import location from 'erpcore/assets/svg/location.svg';
import lock from 'erpcore/assets/svg/lock.svg';
import locked from 'erpcore/assets/svg/locked.svg';
import leaderboards from 'erpcore/assets/svg/leaderboards.svg';
import mail from 'erpcore/assets/svg/mail.svg';
import menu from 'erpcore/assets/svg/menu.svg';
import minus from 'erpcore/assets/svg/minus.svg';
import minusCircle from 'erpcore/assets/svg/minusCircle.svg';
import minusRound from 'erpcore/assets/svg/minusRound.svg';
import minusScale from 'erpcore/assets/svg/minusScale.svg';
import move from 'erpcore/assets/svg/move.svg';
import money from 'erpcore/assets/svg/money.svg';
import notificationError from 'erpcore/assets/svg/notificationError.svg';
import notificationSuccess from 'erpcore/assets/svg/notificationSuccess.svg';
import notificationWarning from 'erpcore/assets/svg/notificationWarning.svg';
import notificationCard from 'erpcore/assets/svg/notificationCard.svg';
import notificationInfo from 'erpcore/assets/svg/notificationInfo.svg';
import notCoordinator from 'erpcore/assets/svg/notCoordinator.svg';
import outputValue from 'erpcore/assets/svg/outputValue.svg';
import otbLogo from 'erpcore/assets/svg/otb-logo.svg';
import passHide from 'erpcore/assets/svg/passHide.svg';
import passShow from 'erpcore/assets/svg/passShow.svg';
import percent from 'erpcore/assets/svg/percent.svg';
import props from 'erpcore/assets/svg/props.svg';
import pause from 'erpcore/assets/svg/pause.svg';
import play from 'erpcore/assets/svg/play.svg';
import plus from 'erpcore/assets/svg/plus.svg';
import plusRound from 'erpcore/assets/svg/plusRound.svg';
import plusScale from 'erpcore/assets/svg/plusScale.svg';
import profile from 'erpcore/assets/svg/profile.svg';
import proTip1 from 'erpcore/assets/svg/proTip1.svg';
import pin from 'erpcore/assets/svg/pin.svg';
import pinOff from 'erpcore/assets/svg/pinOff.svg';
import rotateLeft from 'erpcore/assets/svg/rotateLeft.svg';
import rotateRight from 'erpcore/assets/svg/rotateRight.svg';
import signOut from 'erpcore/assets/svg/signOut.svg';
import search from 'erpcore/assets/svg/search.svg';
import settings from 'erpcore/assets/svg/settings.svg';
import sorting from 'erpcore/assets/svg/sorting.svg';
import starEmpty from 'erpcore/assets/svg/starEmpty.svg';
import starFull from 'erpcore/assets/svg/starFull.svg';
import statistics from 'erpcore/assets/svg/statistics.svg';
import stopwatch from 'erpcore/assets/svg/stopwatch.svg';
import sendMessage from 'erpcore/assets/svg/sendMessage.svg';
import switchIcon from 'erpcore/assets/svg/switchIcon.svg';
import switchCamera from 'erpcore/assets/svg/switchCamera.svg';
import tie from 'erpcore/assets/svg/tie.svg';
import time from 'erpcore/assets/svg/time.svg';
import editTime from 'erpcore/assets/svg/editTime.svg';
import eventCompleted from 'erpcore/assets/svg/eventCompleted.svg';
import clock from 'erpcore/assets/svg/clock.svg';
import upload from 'erpcore/assets/svg/upload.svg';
import usersOnline from 'erpcore/assets/svg/usersOnline.svg';
import elementLoader from 'erpcore/assets/svg/elementLoader.svg';
import eventPausedIllustration from 'erpcore/assets/svg/event-paused-illustration.svg';
import close from 'erpcore/assets/svg/close.svg';
import arrowDown from 'erpcore/assets/svg/arrowDown.svg';
import arrowLeft from 'erpcore/assets/svg/arrowLeft.svg';
import arrowRight from 'erpcore/assets/svg/arrowRight.svg';
import checkmark from 'erpcore/assets/svg/checkmark.svg';
import action from 'erpcore/assets/svg/action.svg';
import notFound from 'erpcore/assets/svg/notFound.svg';
import file from 'erpcore/assets/svg/file.svg';
import arrowDownRight from 'erpcore/assets/svg/arrowDownRight.svg';
import x from 'erpcore/assets/svg/x.svg';
import microphone from 'erpcore/assets/svg/microphone.svg';
import microphoneOff from 'erpcore/assets/svg/microphoneOff.svg';
import myZoneLogo from 'erpcore/assets/svg/myZoneLogo.svg';
import MyZoneColorLogo from 'erpcore/assets/svg/MyZoneColorLogo.svg';
import MyZoneErpHorizontalWhite from 'erpcore/assets/svg/MyZoneErpHorizontalWhite.svg';
import myZoneTicketingBlack from 'erpcore/assets/svg/myZoneTicketingBlack.svg';
import myZoneTicketingColor from 'erpcore/assets/svg/myZoneTicketingColor.svg';
import myZoneTicketingWhite from 'erpcore/assets/svg/myZoneTicketingWhite.svg';
import myZoneTicketingHorizontalWhite from 'erpcore/assets/svg/myZoneTicketingHorizontalWhite.svg';
import exitDoor from 'erpcore/assets/svg/exitDoor.svg';
import shuffle from 'erpcore/assets/svg/shuffle.svg';
import message from 'erpcore/assets/svg/message.svg';
import warning from 'erpcore/assets/svg/warning.svg';
import kickout from 'erpcore/assets/svg/kickout.svg';
import alertBell from 'erpcore/assets/svg/alertBell.svg';
import simpleBell from 'erpcore/assets/svg/simpleBell.svg';
import horizontalLink from 'erpcore/assets/svg/horizontalLink.svg';

export default {
    approve,
    bugReport,
    eas,
    easPowered,
    calculatedAmount,
    cancel,
    chat,
    chatAlternative,
    completion,
    columns,
    camera,
    cameraOff,
    photo,
    crop,
    date,
    dollar,
    drag,
    dashboard,
    download,
    globe,
    hoursBillable,
    hoursLogged,
    reject,
    remove,
    edit,
    editSquare,
    favorites,
    favoritesFull,
    filter,
    flipHorizontal,
    flipVertical,
    group,
    help,
    link,
    linkExternal,
    location,
    lock,
    locked,
    leaderboards,
    mail,
    menu,
    minus,
    minusCircle,
    minusRound,
    minusScale,
    microphone,
    microphoneOff,
    money,
    move,
    notificationError,
    notificationSuccess,
    notificationWarning,
    notificationInfo,
    notificationCard,
    notCoordinator,
    outputValue,
    otbLogo,
    percent,
    profile,
    props,
    passHide,
    passShow,
    pause,
    play,
    plus,
    plusRound,
    plusScale,
    proTip1,
    pin,
    pinOff,
    rotateLeft,
    rotateRight,
    signOut,
    search,
    settings,
    sorting,
    starEmpty,
    starFull,
    statistics,
    stopwatch,
    sendMessage,
    switchIcon,
    switchCamera,
    tie,
    time,
    editTime,
    eventCompleted,
    clock,
    upload,
    usersOnline,
    elementLoader,
    eventPausedIllustration,
    close,
    arrowDown,
    arrowLeft,
    arrowRight,
    checkmark,
    action,
    notFound,
    file,
    myZoneLogo,
    MyZoneColorLogo,
    arrowDownRight,
    MyZoneErpHorizontalWhite,
    myZoneTicketingBlack,
    myZoneTicketingColor,
    myZoneTicketingWhite,
    myZoneTicketingHorizontalWhite,
    x,
    exitDoor,
    shuffle,
    message,
    kickout,
    warning,
    alertBell,
    simpleBell,
    horizontalLink
};
