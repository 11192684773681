import takeRight from 'lodash/takeRight';
import isString from 'lodash/isString';

export const DEFAULT_BUCKET_KEY = 'rest';

export const linearCriticalRelationshipPath = [
    {
        type: 'Event',
        keys: ['event', 'events'],
        // no dedicated bucket
        idTemplate: '/api/events/'
    },
    {
        type: 'EventTeam',
        keys: ['event_team', 'teams'],
        bucket: 'teams',
        idTemplate: '/api/event-teams/',
        forbiddenParentToChild: true
    },
    {
        type: 'EventChallenge',
        keys: ['event_challenge', 'event_challenges'],
        bucket: 'challenges',
        idTemplate: '/api/event-challenges/',
        forbiddenParentToChild: true
    },
    {
        type: 'CompletedChallenge',
        keys: ['completed_challenge', 'completed_challenges'],
        bucket: 'completedChallenges',
        idTemplate: '/api/completed-challenges/',
        forbiddenParentToChild: true
    },
    {
        type: 'EventParticipant',
        keys: ['participants', 'participant'],
        bucket: 'participants',
        idTemplate: '/api/event-participants/',
        forbiddenParentToChild: true
    },
    {
        type: 'HelpRequest',
        keys: ['help-requests', 'help-request'],
        bucket: 'helpRequests',
        idTemplate: '/api/help-requests/',
        forbiddenParentToChild: true
    },
    {
        type: 'EventChallengeTimer',
        keys: ['timers', 'timer'],
        bucket: 'challengeTimers',
        idTemplate: '/api/event-challenge-timers/',
        forbiddenParentToChild: false
    },
    {
        type: 'EventContent',
        keys: ['event_content', 'event_content'],
        bucket: 'eventContent',
        idTemplate: '/api/event-contents/',
        forbiddenParentToChild: false
    },
    {
        type: 'EventContentItem',
        keys: ['event_content_items', 'event_content_item', 'items'],
        bucket: 'eventContentItems',
        idTemplate: '/api/event-content-items/',
        forbiddenParentToChild: false
    },
    {
        type: 'ChallengeType',
        bucket: 'challengeTypes',
        idTemplate: '/api/challenge-types/',
        forbiddenParentToChild: false
    }
];

export const getForbiddenChildrenRelationshipKeys = (relationshipType, excludeItself = false) => {
    const typeIndex = linearCriticalRelationshipPath.findIndex(
        item => item.type === relationshipType
    );

    if (typeIndex < 0) {
        return [];
    }

    const offset = excludeItself ? 1 : 0;

    const forbiddenRelationships = takeRight(
        linearCriticalRelationshipPath,
        linearCriticalRelationshipPath.length - typeIndex - offset
    );

    return forbiddenRelationships.reduce((accumulator, currentValue) => {
        if (currentValue?.forbiddenParentToChild && currentValue?.keys?.length) {
            accumulator.push(currentValue.keys);
        }
        return accumulator;
    }, []);
};

export const getBucketKeyFromType = type => {
    const entityData = linearCriticalRelationshipPath.find(item => item.type === type);
    return entityData?.bucket || DEFAULT_BUCKET_KEY;
};

export const getBucketKeyFromIri = iri => {
    if (!iri || !isString(iri)) {
        return null;
    }
    const entityData = linearCriticalRelationshipPath.find(item => {
        const providedIriTemplate = iri.replace(/[0-9]/g, '');
        return item.idTemplate === providedIriTemplate;
    });
    return entityData?.bucket || DEFAULT_BUCKET_KEY;
};

export const includedMappings = {
    activity: 'activity',
    company: 'company,company.logo',
    image: 'image,image.versions',
    coordinators: 'coordinators,coordinators.image,coordinators.image.versions',
    teams: 'teams',
    participants: 'participants,participants.image,participants.image.versions',
    teamParticipantsTimers: 'eventTeam,eventTeam.participants,eventTeam.timers',
    challenges:
        'eventChallenges,eventChallenges.timers,eventChallenges.challenge,eventChallenges.media',
    completedChallenges: 'completedChallenges',
    guideline: 'guideline',
    contents: 'contents,contents.items,contents.items.media,contents.items.media.media',
    helpRequests: 'helpRequests'
};

export const routeRequiredIncludes = {
    cache: ['activity', 'challenges', 'coordinators', 'image', 'company', 'guideline', 'contents'],
    lobby: ['participants'],
    game: ['helpRequests'],
    me: ['teamParticipantsTimers']
};

export const getRouteIncludedString = (route, additional = []) => {
    const requiredIncludes = routeRequiredIncludes[route]?.length
        ? routeRequiredIncludes[route]
        : null;

    let includedString = [];

    if (Array.isArray(additional) && additional?.length) {
        includedString = [...additional];
    }

    if (requiredIncludes) {
        const computedIncludedString = requiredIncludes
            .reduce((accumulator, currentValue) => {
                if (includedMappings[currentValue]) {
                    accumulator.push(includedMappings[currentValue]);
                }
                return accumulator;
            }, [])
            .join(',');

        includedString.push(computedIncludedString);
    }

    return includedString.length ? includedString.join(',') : '';
};
