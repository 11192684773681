import moment from 'moment';

export const actions = {
    REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
    SET_PAGE_NOTIFICATION: 'SET_PAGE_NOTIFICATION',
    ADD_PAGE_NOTIFICATION: 'ADD_PAGE_NOTIFICATION',
    REMOVE_PAGE_NOTIFICATION: 'REMOVE_PAGE_NOTIFICATION',
    REMOVE_PAGE_NOTIFICATIONS: 'REMOVE_PAGE_NOTIFICATIONS',
    SET_PERMANENT_PAGE_NOTIFICATION: 'SET_PERMANENT_PAGE_NOTIFICATION',
    ADD_PERMANENT_PAGE_NOTIFICATION: 'ADD_PERMANENT_PAGE_NOTIFICATION',
    REMOVE_PERMANENT_PAGE_NOTIFICATION: 'REMOVE_PERMANENT_PAGE_NOTIFICATION',
    REMOVE_PERMANENT_PAGE_NOTIFICATIONS: 'REMOVE_PERMANENT_PAGE_NOTIFICATIONS',
    SET_FLOATING_NOTIFICATION: 'SET_FLOATING_NOTIFICATION',
    ADD_FLOATING_NOTIFICATION: 'ADD_FLOATING_NOTIFICATION',
    REMOVE_FLOATING_NOTIFICATION: 'REMOVE_FLOATING_NOTIFICATION',
    REMOVE_FLOATING_NOTIFICATIONS: 'REMOVE_FLOATING_NOTIFICATIONS',
    SET_EVENT_NOTIFICATION: 'SET_EVENT_NOTIFICATION',
    ADD_EVENT_NOTIFICATION: 'ADD_EVENT_NOTIFICATION',
    SHOW_EVENT_NOTIFICATION: 'SHOW_EVENT_NOTIFICATION',
    UPDATE_EVENT_NOTIFICATION: 'UPDATE_EVENT_NOTIFICATION',
    SET_PARTICIPANT_NOTIFICATION: 'SET_PARTICIPANT_NOTIFICATION',
    SET_PARTICIPANT_NOTIFICATION_COUNT: 'SET_PARTICIPANT_NOTIFICATION_COUNT',
    CLEAR_PARTICIPANT_NOTIFICATIONS: 'CLEAR_PARTICIPANT_NOTIFICATIONS'
};

export const initialState = {
    pageNotifications: [],
    permanentPageNotifications: [],
    floatingNotifications: [],
    eventNotifications: [],
    participantNotifications: [],
    participantNotificationsCount: 0
};

const obtainNotificationKey = (response, position) => {
    /*
    let key = response?.code;

    if (response?.errors?.code) {
        key = `${response.code}-${response.errors.code}`;
    } else if (response?.data?.id) {
        key = `${response.code}-${response.data.id}`;
        // eslint-disable-next-line camelcase
    } else if (response?.id) {
        key = `${response.code}-${response.id}`;
        // eslint-disable-next-line camelcase
    } else if (response?.error_key) {
        // eslint-disable-next-line camelcase
        key = `${response.code}-${response.error_key}`;
    }
    */
    if (response?.identifier) {
        return response.identifier;
    }

    const { timeStamp, ...rest } = { ...response };
    return `${position}-${JSON.stringify(rest)}`; // response is always non-circular object without references, safe for JSON.stringify() operation
};

const formatNotificationData = (response, position) => {
    return { ...response, identifier: obtainNotificationKey(response, position) };
};

export default (state = initialState, { type, response, identifier, initiator, origin }) => {
    switch (type) {
        // PAGE NOTIFICATIONS
        case actions.SET_PAGE_NOTIFICATION: {
            return {
                ...state,
                pageNotifications: [formatNotificationData(response, 'page')]
            };
        }
        case actions.ADD_PAGE_NOTIFICATION: {
            const pageNotifications = [...state.pageNotifications];
            pageNotifications.unshift(formatNotificationData(response, 'page'));
            return {
                ...state,
                pageNotifications
            };
        }
        case actions.REMOVE_PAGE_NOTIFICATION: {
            return {
                ...state,
                pageNotifications: [...state.pageNotifications].filter(item => {
                    return item.identifier !== identifier;
                })
            };
        }
        case actions.REMOVE_PAGE_NOTIFICATIONS: {
            if (initiator && initiator === 'router') {
                return {
                    ...state,
                    pageNotifications: [...state.pageNotifications].filter(item => {
                        return item.skipNotificationClearOnRouteChange;
                    })
                };
            }
            return {
                ...state,
                pageNotifications: []
            };
        }

        // PERMANENT PAGE NOTIFICATIONS

        case actions.SET_PERMANENT_PAGE_NOTIFICATION: {
            return {
                ...state,
                permanentPageNotifications: [formatNotificationData(response, 'permanentPage')]
            };
        }
        case actions.ADD_PERMANENT_PAGE_NOTIFICATION: {
            const permanentPageNotifications = [...state.permanentPageNotifications];
            permanentPageNotifications.unshift(formatNotificationData(response, 'permanentPage'));
            return {
                ...state,
                permanentPageNotifications
            };
        }
        case actions.REMOVE_PERMANENT_PAGE_NOTIFICATION: {
            return {
                ...state,
                permanentPageNotifications: [...state.permanentPageNotifications].filter(item => {
                    return item.identifier !== identifier;
                })
            };
        }
        case actions.REMOVE_PERMANENT_PAGE_NOTIFICATIONS: {
            return {
                ...state,
                permanentPageNotifications: []
            };
        }

        // FLOATING NOTIFICATIONS

        case actions.SET_FLOATING_NOTIFICATION: {
            return {
                ...state,
                floatingNotifications: [formatNotificationData(response, 'floating')]
            };
        }
        case actions.ADD_FLOATING_NOTIFICATION: {
            const floatingNotifications = [...state.floatingNotifications];
            floatingNotifications.unshift(formatNotificationData(response, 'floating'));
            return {
                ...state,
                floatingNotifications
            };
        }
        case actions.REMOVE_FLOATING_NOTIFICATION: {
            return {
                ...state,
                floatingNotifications: [...state.floatingNotifications].filter(item => {
                    return item.identifier !== identifier;
                })
            };
        }
        case actions.REMOVE_FLOATING_NOTIFICATIONS: {
            return {
                ...state,
                floatingNotifications: []
            };
        }

        // Event Notifications
        case actions.ADD_EVENT_NOTIFICATION: {
            const eventNotifications = [...state.eventNotifications];
            // let { participantNotificationsCount } = { ...state };

            const notificationExists = id => {
                const match = eventNotifications.find(
                    ({ id: notificationId }) => notificationId === id
                );

                if (match) {
                    const index = eventNotifications.findIndex(item => item?.id === id);

                    eventNotifications[index] = formatNotificationData(
                        {
                            id: response?.id,
                            content: response?.content,
                            time: response?.time,
                            _type: response?._type,
                            display: false,
                            title: '',
                            description: '',
                            iconColor: '',
                            duration: 0,
                            type: '',
                            origin: '',
                            timeStamp: response?.timeStamp
                                ? moment(response?.timeStamp).toISOString()
                                : moment().toISOString()
                        },
                        'event'
                    );
                }

                return !!match;
            };

            if (Array.isArray(response)) {
                const newNotifications = response.filter(({ id }) => !notificationExists(id));

                newNotifications.map(({ id, content, time, _type }) =>
                    eventNotifications.unshift(
                        formatNotificationData(
                            {
                                id,
                                content,
                                time,
                                _type,
                                display: false,
                                title: '',
                                description: '',
                                iconColor: '',
                                duration: 0,
                                type: '',
                                origin: '',
                                timeStamp: moment().toISOString()
                            },
                            'event'
                        )
                    )
                );
            } else if (!notificationExists(response?.id)) {
                // if (response?.origin === 'Mercure') participantNotificationsCount += 1;
                eventNotifications.unshift(formatNotificationData(response, 'event'));
            }
            return {
                ...state,
                eventNotifications
                // participantNotificationsCount
            };
        }
        case actions.SHOW_EVENT_NOTIFICATION: {
            const modifiedNotifications = [...state.eventNotifications].map(item => {
                return item.identifier === identifier
                    ? { ...item, display: true, duration: 1, timeStamp: moment().toISOString() }
                    : item;
            });
            return {
                ...state,
                eventNotifications: modifiedNotifications
            };
        }

        case actions.UPDATE_EVENT_NOTIFICATION: {
            return {
                ...state,
                eventNotifications: [...state.eventNotifications].map(item => {
                    return item.identifier === identifier ? { ...item, ...response } : item;
                })
            };
        }

        // GENERAL
        case actions.REMOVE_NOTIFICATION: {
            return {
                ...state,
                pageNotifications: [...state.pageNotifications].filter(item => {
                    return item.identifier !== identifier;
                }),
                permanentPageNotifications: [...state.permanentPageNotifications].filter(item => {
                    return item.identifier !== identifier;
                }),
                floatingNotifications: [...state.floatingNotifications].filter(item => {
                    return item.identifier !== identifier;
                }),
                eventNotifications:
                    origin === 'Mercure'
                        ? [...state.eventNotifications].filter(item => {
                              return item.identifier !== identifier;
                          })
                        : [...state.eventNotifications].map(item => {
                              return item.identifier === identifier
                                  ? { ...item, display: false }
                                  : item;
                          })
            };
        }

        // Participant Notifications
        case actions.SET_PARTICIPANT_NOTIFICATION: {
            const participantNotifications = [...state.participantNotifications];

            const notificationExists = id => {
                const match = participantNotifications.find(
                    ({ id: notificationId }) => notificationId === id
                );

                if (match) {
                    const index = participantNotifications.findIndex(item => item?.id === id);
                    participantNotifications[index] = formatNotificationData(
                        {
                            id: match?.id,
                            content: match?.content,
                            time: match?.time,
                            title: match?.title,
                            type: match?.type,
                            timeStamp: match?.timeStamp
                        },
                        'participant'
                    );
                }
                return match;
            };

            if (Array.isArray(response)) {
                const newNotifications = response.filter(({ id }) => !notificationExists(id));
                newNotifications.map(({ id, content, time, type: _type, title, timeStamp }) =>
                    participantNotifications.unshift(
                        formatNotificationData(
                            {
                                id,
                                content,
                                time,
                                title,
                                type: _type,
                                timeStamp
                            },
                            'participant'
                        )
                    )
                );
            } else if (!notificationExists(response?.id))
                participantNotifications.push(formatNotificationData(response, 'participant'));

            return {
                ...state,
                participantNotifications,
                participantNotificationsCount: participantNotifications?.length
            };
        }
        case actions.SET_PARTICIPANT_NOTIFICATION_COUNT:
            return {
                ...state,
                participantNotificationsCount: state?.participantNotificationsCount + response
            };
        case actions.CLEAR_PARTICIPANT_NOTIFICATIONS: {
            return {
                ...state,
                participantNotifications: []
            };
        }
        default:
            return state;
    }
};
