import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

//  Importing Reducers
// General Reducers
import { reducer as form } from 'redux-form';
import serverTime from 'erpcore/utils/ServerTimeManager/ServerTimeManager.reducer';
import authManager from 'erpcore/utils/AuthManager/AuthManager.reducer';
import erpUserManager from 'erpcore/utils/ErpUserManager/ErpUserManager.reducer';
import routerManager from 'erpcore/utils/RouterManager/RouterManager.reducer';
import layoutManager from 'erpcore/utils/LayoutManager/LayoutManager.reducer';
import notificationManager from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import resourceManager from 'erpcore/utils/ResourceManager/ResourceManager.reducer';
import imageManager from 'erpcore/components/ImageManager/ImageManager.reducer';
import listing from 'erpcore/components/Listing/Listing.reducer';
import join from 'erpcore/screens/Join/Join.reducer';
import event from 'erpcore/screens/Event/Event.reducer';
import conference from 'erpcore/components/VideoModule/VideoModule.reducer';

//  Combining all existing reducers
const appReducer = combineReducers({
    serverTime,
    authManager,
    erpUserManager,
    routerManager,
    layoutManager,
    notificationManager,
    listing,
    form,
    imageManager,
    join,
    event,
    conference,
    resourceManager
});

const rootReducer = (state, action) => {
    if (action.type === 'SIGN_OUT_SUCCESSFUL') {
        storage.removeItem('persist:root');
        const conferenceCache = state.conference;
        //  clearing out state
        //  filling out state with cached data
        state = {};
        state.conference = conferenceCache;
    }

    return appReducer(state, action);
};

export default rootReducer;
