import { createSelector } from 'reselect';

export const getPageNotifications = state => state.notificationManager.pageNotifications;

export const getPermanentPageNotifications = state =>
    state.notificationManager.permanentPageNotifications;

export const getFloatingNotifications = state => state.notificationManager.floatingNotifications;

export const getEventNotifications = state => state.notificationManager.eventNotifications;

export const getParticipantNotifications = state =>
    state.notificationManager.participantNotifications;

export const getParticipantNotificationsCount = state =>
    state.notificationManager.participantNotificationsCount;

export default createSelector([
    getPageNotifications,
    getPermanentPageNotifications,
    getFloatingNotifications,
    getEventNotifications,
    getParticipantNotifications,
    getParticipantNotificationsCount
]);
