export const actions = {
    START_SIGN_OUT: 'START_SIGN_OUT',
    SIGN_OUT_SUCCESSFUL: 'SIGN_OUT_SUCCESSFUL',
    STORE_ACTIVE_USER_DATA: 'STORE_ACTIVE_USER_DATA',
    STORE_ACTIVE_EVENT_DATA: 'STORE_ACTIVE_EVENT_DATA',
    STORE_ACTIVE_TEAM_DATA: 'STORE_ACTIVE_TEAM_DATA',
    START_UPDATE_USER_TRACK_SETTINGS: 'START_UPDATE_USER_TRACK_SETTINGS',

    START_FETCHING_ME: 'START_FETCHING_ME',
    FETCHING_ME_SUCCESSFULL: 'FETCHING_ME_SUCCESSFULL',
    FETCHING_ME_FAILED: 'FETCHING_ME_FAILED',

    CLEAN_ACTIVES: 'CLEAN_ACTIVES'
};

export const initialState = {
    activeUser: null,
    activeEvent: null,
    activeTeam: null,
    userTrackSettings: {
        video_muted: false,
        audio_muted: false,
        userAudioDeviceID: null,
        userVideoDeviceID: null
    }
};

export default (state = initialState, { type, response, trackType, value }) => {
    switch (type) {
        case actions.CLEAN_ACTIVES: {
            // TODO: antonio: ???
            return {
                activeUser: null,
                activeEvent: null,
                activeTeam: null,
                userTrackSettings: {
                    video_muted: false,
                    audio_muted: false,
                    userAudioDeviceID: null,
                    userVideoDeviceID: null
                }
            };
        }
        case actions.START_UPDATE_USER_TRACK_SETTINGS: {
            return {
                ...state,
                userTrackSettings: {
                    ...state.userTrackSettings,
                    [`${trackType}_muted`]: value
                }
            };
        }
        case actions.START_FETCHING_ME: {
            return {
                ...state,
                fetchingMe: true
            };
        }
        case actions.FETCHING_ME_SUCCESSFULL: {
            return {
                ...state,
                fetchingMe: false
            };
        }
        case actions.FETCHING_ME_FAILED: {
            return {
                ...state,
                fetchingMe: false
            };
        }
        case actions.STORE_ACTIVE_USER_DATA: {
            return {
                ...state,
                activeUser: response
            };
        }
        case actions.STORE_ACTIVE_EVENT_DATA: {
            return {
                ...state,
                activeEvent: response
            };
        }
        case actions.STORE_ACTIVE_TEAM_DATA: {
            return {
                ...state,
                activeTeam: response
            };
        }
        default:
            return state;
    }
};
