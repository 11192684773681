import Main from 'erpcore/components/Layout/Main';

const defaultLayout = {
    data: [
        {
            slotName: 'main',
            component: Main,
            position: 1
        }
    ]
};

export default defaultLayout;
