import { useEffect, useCallback } from 'react';
import throttle from 'lodash/throttle';

const AppHeight = () => {
    const setHeightValue = useCallback(() => {
        document.body.style.setProperty('--appHeight', `${window.innerHeight}px`);
    }, []);

    useEffect(() => {
        setHeightValue();
        const throttleHandler = throttle(setHeightValue, 100);
        window.addEventListener('resize', throttleHandler);
        window.addEventListener('orientationchange', setHeightValue);

        return () => {
            window.removeEventListener('resize', throttleHandler);
            window.removeEventListener('orientationchange', setHeightValue);
        };
    }, []);

    return null;
};

export default AppHeight;
