import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { store, persistor } from 'erpcore/store';
import 'erpcore/assets/scss/global.scss';
import RouterManager from 'erpcore/utils/RouterManager';
import * as serviceWorker from 'erpcore/serviceWorker';

/**
|--------------------------------------------------
| Main app initialization
| Preparing Redux for App with Providers
| Rendering App inside wrapper element
|--------------------------------------------------
*/
render(
    <PersistGate persistor={persistor}>
        <Provider store={store}>
            <Router>
                <RouterManager />
            </Router>
        </Provider>
    </PersistGate>,
    document.getElementById('wrapper')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
