import axios from 'axios';
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { clearHash, clearToken, setHash } from 'erpcore/api';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as resourceManagerActions } from 'erpcore/utils/ResourceManager/ResourceManager.reducer';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';
import { actions as erpUserActions } from 'erpcore/utils/ErpUserManager/ErpUserManager.reducer';
import restClient from 'erpcore/api/restClient';
import { getEventData, getEventParticipantBy } from 'erpcore/screens/Join/Join.selectors';
import dto from 'erpcore/utils/dto';
import { getActiveEvent } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { actions as eventActions } from './Join.reducer';

const generateParticipant = existingParticipant => ({
    id: existingParticipant?.id,
    first_name: existingParticipant?.first_name,
    last_name: existingParticipant?.last_name,
    active: existingParticipant?.active,
    email: existingParticipant?.email,
    nickname: existingParticipant?.nickname,
    hash: existingParticipant?.hash,
    image: existingParticipant?.image
});

/**
 * Uploads image
 * @param {Object} Image data
 * @param {String} xAuthToken - Auth token of participant
 * @return {Promise} MediaObject Data
 */
export const uploadEventParticipantImage = (formData, xAuthToken) => {
    return new Promise(async (resolve, reject) => {
        try {
            try {
                formData.append('visible', false);
            } catch (e) {
                //
            }
            const request = await restClient.post(`api/media-objects`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-AUTH-TOKEN': xAuthToken
                }
            });

            const { data: responseData } = request;

            if (responseData) {
                const mediaObject = { ...dto(responseData) };

                resolve({ code: responseData?.code, ...mediaObject });
            }
        } catch (error) {
            reject(error?.response?.data || error);
        }
    });
};

/**
 * Process Image file
 * @param {String} Participant Auth token
 * @return {Promise} MediaObject Data or null
 */
const processFile = ({ xAuthToken }) => {
    const canvas = document.getElementById('screenshot_image');

    if (canvas && canvas.getAttribute('data-has-photo') === 'true') {
        return new Promise((resolve, reject) => {
            canvas.toBlob(async blob => {
                const formData = new FormData();
                formData.append('file', blob);
                formData.append(
                    'meta',
                    JSON.stringify({ filename: 'image.jpg', type: 'image/jpeg' })
                );

                try {
                    const response = await uploadEventParticipantImage(formData, xAuthToken);

                    resolve(response);
                } catch (error) {
                    reject(error);
                }
            });
        });
    }

    return null;
};

/**
 * Fetch Event Data
 * @param {Object} promise Promises
 * @param {String} hash Event hash
 * @param {Object} params Endpoint params
 * @return {Object} Response from API
 */
export function* fetchEventData({ promise, hash }) {
    try {
        //  Rest client without x-auth-token
        const cleanRestClient = axios.create({
            baseURL: process.env.REACT_APP_REST_API,
            timeout: process.env.REACT_APP_API_TIMEOUT,
            transformRequest: (data, headers) => {
                delete headers['X-AUTH-TOKEN'];
                delete headers.Authorization;
            }
        });
        const fetchEventDataAPI = yield cleanRestClient.get(`/api/events/by-hash-simple/${hash}`);
        const eventData = dto(fetchEventDataAPI?.data, false, false);
        yield put({
            type: eventActions.FETCHING_EVENT_DATA_SUCCESSFUL,
            response: eventData
        });
        if (promise) yield call(promise.resolve, eventData);
        return eventData;
    } catch (error) {
        yield put({
            type: eventActions.FETCHING_EVENT_DATA_FAILED
        });
        const errorData = error?.response?.data || error;
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: errorData
        });
        if (promise) yield call(promise.reject, errorData);
        return errorData;
    }
}

/**
 * Updating event participant with form data
 * @param {Object} promise Promises
 * @param {Object} Participant form data
 * @return {Object} Response from API
 */
export function* updateEventParticipant({ promise, participant }) {
    try {
        // TODO: antonio: fix object mutation --> participant?.active
        delete participant?.active;

        const updateEventParticipantAPI = yield restClient.put(
            `api/event-participant/me`,
            participant,
            {
                headers: { 'X-AUTH-TOKEN': participant?.hash }
            }
        );
        const participantData = dto(updateEventParticipantAPI?.data);
        yield put({
            type: eventActions.UPDATE_EVENT_PARTICIPANT_SUCCESSFUL,
            response: participantData
        });
        if (promise) yield call(promise.resolve, participantData);
        return participantData;
    } catch (error) {
        yield put({
            type: eventActions.UPDATE_EVENT_PARTICIPANT_FAILED
        });
        const errorData = error?.response?.data || error;
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: errorData
        });
        if (promise) yield call(promise.reject, errorData);
        return errorData;
    }
}

/**
 * Create event participant with form data
 * @param {Object} promise Promises
 * @param {Object} Participant form data
 * @param {string} hash Event hash
 * @return {Object} Response from API
 */
export function* createEventParticipant({ promise, participant, hash }) {
    try {
        //  Rest client without x-auth-token
        const cleanRestClient = axios.create({
            baseURL: process.env.REACT_APP_REST_API,
            timeout: process.env.REACT_APP_API_TIMEOUT,
            transformRequest: (data, headers) => {
                headers['Content-Type'] = 'application/json';
                delete headers['X-AUTH-TOKEN'];
                return JSON.stringify(data);
            }
        });
        const createEventParticipantAPI = yield cleanRestClient.post(
            `/api/events/${hash}/participant`,
            participant
        );
        const participantData = createEventParticipantAPI?.data;
        yield put({
            type: eventActions.CREATE_EVENT_PARTICIPANT_SUCCESSFUL,
            response: participantData
        });
        if (promise) yield call(promise.resolve, participantData);
        return participantData;
    } catch (error) {
        yield put({
            type: eventActions.CREATE_EVENT_PARTICIPANT_FAILED
        });
        const errorData = error?.response?.data || error;
        if (errorData?.code !== 'participant.exists') {
            yield put({
                type: notificationManagerActions.SET_PAGE_NOTIFICATION,
                response: errorData
            });
        }
        if (promise) yield call(promise.reject, errorData);
        throw errorData;
    }
}

/**
 * Join event participant with form data
 * @param {Object} promise Promises
 * @param {Object} Participant form data
 * @param {string} hash Event hash
 * @return {Object} Response from API
 */
export function* joinEventParticipant({ promise, participant, hash }) {
    try {
        //  Get existing participants from event if they exist
        const participantExistsByEmail = yield select(state =>
            getEventParticipantBy(state, {
                email: participant?.email
            })
        );
        const participantExistsByNickname = yield select(state =>
            getEventParticipantBy(state, {
                nickname: participant?.nickname
            })
        );

        //  Determine if new participant should be created or update existing one
        let participantAPI = null;
        if (participant?.hash || participantExistsByEmail?.length > 0) {
            //  Update participant if exists by email
            const existingParticipant = generateParticipant(participantExistsByEmail[0]);
            // Check if any image is selected by user, if yes it will be replaced, returns null if no image is taken
            const mediaObject = yield call(processFile, {
                xAuthToken: existingParticipant?.hash || participant?.hash
            });

            participantAPI = yield call(updateEventParticipant, {
                participant: {
                    ...existingParticipant,
                    ...participant,
                    image: mediaObject ? mediaObject?.id : existingParticipant?.image
                }
            });
        } else if (participantExistsByNickname?.length > 0) {
            //  Update participant if exists by nickname
            const existingParticipant = generateParticipant(participantExistsByNickname[0]);
            // Check if any image is selected by user, if yes it will be replaced, returns null if no image is taken
            const mediaObject = yield call(processFile, { xAuthToken: existingParticipant?.hash });

            participantAPI = yield call(updateEventParticipant, {
                participant: {
                    ...existingParticipant,
                    ...participant,
                    image: mediaObject ? mediaObject?.id : existingParticipant?.image
                }
            });
        } else {
            //  Create new participant
            participantAPI = yield call(createEventParticipant, { participant, hash });
            const existingParticipant = generateParticipant(participantAPI);
            const mediaObject = yield call(processFile, { xAuthToken: participantAPI?.hash });

            participantAPI = yield call(updateEventParticipant, {
                participant: {
                    ...existingParticipant,
                    ...participant,
                    image: mediaObject?.id
                }
            });
        }
        setHash(participantAPI?.hash);
        //  Clearing resource reducer
        yield put({
            type: resourceManagerActions.CLEAR_RESOURCES
        });
        //  Store participant data to auth manager to persist
        yield put({
            type: authActions.STORE_ACTIVE_USER_DATA,
            response: {
                hash: participantAPI?.hash,
                id: participantAPI?.id,
                jwt: participantAPI?.jwt
            }
        });
        //  Store event data to auth manager persistor
        const eventData = yield select(getEventData);
        yield put({
            type: authActions.STORE_ACTIVE_EVENT_DATA,
            response: { hash, id: eventData?.id }
        });
        yield put({
            type: authActions.STORE_ACTIVE_TEAM_DATA,
            response: undefined
        });
        yield put({
            type: eventActions.JOIN_EVENT_PARTICIPANT_SUCCESSFUL
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventActions.JOIN_EVENT_PARTICIPANT_FAILED
        });
        const errorData = error?.response?.data || error;
        if (errorData?.code !== 'participant.exists') {
            yield put({
                type: notificationManagerActions.SET_PAGE_NOTIFICATION,
                response: errorData
            });
        }
        yield call(promise.reject, errorData);
    }
}

/**
 * Join event participant via magic link (/join-direct)
 * @param {Object} promise Promises
 * @param {Object} participantData participant data
 * @param {Object} eventData Event data
 * @return {Object} Response from API
 */
export function* directJoinEventParticipant({ promise, participantData, eventData }) {
    try {
        clearToken();
        clearHash();
        setHash(participantData?.hash);

        // clear resource reducer
        yield put({
            type: resourceManagerActions.CLEAR_RESOURCES
        });

        // clear admin data
        yield put({
            type: erpUserActions.DELETE_ERP_USER_DATA
        });

        //  Store participant data
        yield put({
            type: authActions.STORE_ACTIVE_USER_DATA,
            response: {
                hash: participantData?.hash,
                id: participantData?.id,
                jwt: participantData?.jwt
            }
        });

        // store active event data
        yield put({
            type: authActions.STORE_ACTIVE_EVENT_DATA,
            response: { hash: eventData?.hash, id: eventData?.id }
        });
        yield put({
            type: authActions.STORE_ACTIVE_TEAM_DATA,
            response: undefined
        });
        yield put({
            type: eventActions.JOIN_EVENT_PARTICIPANT_SUCCESSFUL
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventActions.JOIN_EVENT_PARTICIPANT_FAILED
        });
        const errorData = error?.response?.data || error;
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: errorData
        });
        yield call(promise.reject, errorData);
    }
}

/**
 * Starts an event
 * @param {Object} promise Promises
 * @return {Object} Response from API
 */
export function* startEvent({ promise }) {
    try {
        const activeEvent = yield select(getActiveEvent);
        yield restClient.put(`${activeEvent?.id}/start`, {
            headers: { 'Content-type': 'application/json' }
        });
        yield put({
            type: eventActions.START_EVENT_SUCCESSFUL
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventActions.START_EVENT_FAILED
        });
        const errorData = error?.response?.data || error;
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: errorData
        });
        yield call(promise.reject, errorData);
    }
}

/**
 * Register action to watcher
 */
export const joinSaga = [
    takeLatest(eventActions.START_FETCHING_EVENT_DATA, fetchEventData),
    takeLatest(eventActions.START_UPDATE_EVENT_PARTICIPANT, updateEventParticipant),
    takeLatest(eventActions.START_CREATE_EVENT_PARTICIPANT, createEventParticipant),
    takeLatest(eventActions.START_JOIN_EVENT_PARTICIPANT, joinEventParticipant),
    takeLatest(eventActions.START_DIRECT_JOIN_EVENT_PARTICIPANT, directJoinEventParticipant),
    takeLatest(eventActions.START_EVENT, startEvent)
];
