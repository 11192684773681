import restClient from 'erpcore/api/restClient';
import { call, put, takeLatest } from 'redux-saga/effects';
import { actions as videoModuleActions } from 'erpcore/components/VideoModule/VideoModule.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';

/**
 * Post Chat message
 * @param {formData} object formData
 * @return {Object} Response from API
 */
export function* postChatMessage({ promise, formData }) {
    try {
        const postChatMessageAPI = yield restClient.post('api/chat-messages', formData);
        const participantData = postChatMessageAPI?.data;
        yield put({
            type: videoModuleActions.POSTING_CHAT_MESSAGE_SUCCESSFUL,
            response: participantData
        });
        yield call(promise.resolve, participantData);
    } catch (error) {
        yield put({
            type: videoModuleActions.POSTING_CHAT_MESSAGE_FAILED
        });
        const errorData = error?.response?.data || error;
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: errorData
        });
        yield call(promise.reject, errorData);
    }
}

/**
 * Fetch Chat messages
 * @param {Object} promise Promises
 * @param {String} hash Event hash
 * @param {Object} params Endpoint params
 * @return {Object} Response from API
 */
export function* fetchChatMessages({ promise, params }) {
    try {
        const fetchChatMessagesAPI = yield restClient.get('/api/chat-messages', {
            params
        });
        yield put({
            type: videoModuleActions.FETCHING_CHAT_MESSAGES_SUCCESSFUL
        });
        yield call(promise.resolve, dto(fetchChatMessagesAPI?.data));
    } catch (error) {
        yield put({
            type: videoModuleActions.FETCHING_CHAT_MESSAGES_FAILED
        });
        const errorData = error?.response?.data || error;
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: errorData
        });
        yield call(promise.reject, errorData);
    }
}

/**
 * Register action to watcher
 */
export const videoModuleSaga = [
    takeLatest(videoModuleActions.START_POSTING_CHAT_MESSAGE, postChatMessage),
    takeLatest(videoModuleActions.START_FETCHING_CHAT_MESSAGES, fetchChatMessages)
];
