import { actions as videoModuleActions } from 'erpcore/components/VideoModule/VideoModule.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';
import { store } from 'erpcore/store';

/**
 * Get Available devices on the device
 * @return {Object} - all available devices
 */
const getDevices = () => {
    return new Promise(async (resolve, reject) => {
        try {
            await window.JitsiMeetJS.mediaDevices.enumerateDevices(deviceList => {
                const videoSources = [];
                const audioInputSources = [];
                const audioOutputSources = [];

                deviceList.forEach(device => {
                    if (device.kind === 'videoinput') {
                        videoSources.push(device);
                    } else if (device.kind === 'audioinput') {
                        audioInputSources.push(device);
                    } else if (device.kind === 'audiooutput') {
                        audioOutputSources.push(device);
                    }
                });

                const devices = { videoSources, audioInputSources, audioOutputSources };

                store.dispatch({
                    type: videoModuleActions.STORE_DEVICES,
                    devices
                });

                resolve(devices);
            });
        } catch (e) {
            console.error(e);
            reject(e);
        }
    });
};

/**
 * Available Devices to change to
 * @return {Promise<Array>}
 */
const devicesAvailable = () => {
    return new Promise((resolve, reject) => {
        try {
            window.JitsiMeetJS.mediaDevices.enumerateDevices(deviceList => {
                const devices = [];

                deviceList.forEach(device => {
                    if (
                        device.kind === 'videoinput' &&
                        !devices.includes('video') &&
                        window.JitsiMeetJS.mediaDevices.isDeviceChangeAvailable(device.deviceId)
                    ) {
                        devices.push('video');
                    }
                    if (
                        device.kind === 'audioinput' &&
                        !devices.includes('audio') &&
                        window.JitsiMeetJS.mediaDevices.isDeviceChangeAvailable(device.deviceId)
                    ) {
                        devices.push('audio');
                    }
                });

                resolve(devices);
            });
        } catch (e) {
            reject(e);
        }
    });
};

/**
 * Download needed scripts for jitsi ( jquery and strophe libraries )
 * @param domain {String}
 * @param room {String}
 * @return {Promise<unknown>}
 */
const loadJitsiScript = () => {
    return new Promise((resolve, reject) => {
        const jquery = document.createElement('script');
        jquery.src = `https://code.jquery.com/jquery-3.5.1.min.js`;
        document.body.append(jquery);

        const script = document.createElement('script');
        script.src = `${process.env.REACT_APP_RTC_API}/libs/lib-jitsi-meet.min.js`;
        script.onload = () => {
            window.JitsiMeetJS.init();
            window.JitsiMeetJS.setLogLevel(window.JitsiMeetJS.logLevels.ERROR);

            const configScript = document.createElement('script');
            configScript.src = `${process.env.REACT_APP_RTC_API}/config.js`;
            document.body.append(configScript);

            configScript.onload = () => {
                store.dispatch({
                    type: videoModuleActions.SET_JITSI_CONFIGURATION,
                    payload: window.config
                });
                resolve();
            };

            configScript.onerror = () => {
                reject();
            };
        };
        script.onerror = () => {
            reject();
        };
        document.body.append(script);
    });
};

export const createLocalTracks = (params = null) => {
    return new Promise(async (resolve, reject) => {
        try {
            const localTracks = await window.JitsiMeetJS.createLocalTracks({ ...params });
            resolve(localTracks);
        } catch (e) {
            reject(e);
        }
    });
};

/**
 * Create local track ( video and audio ) and store them to redux
 * @param {Array} devices - array of devices available ( can be one of: 'video', 'audio', 'desktop' or empty [] )
 * @return {void}
 */
const getAndSetLocalTrack = async devices => {
    return new Promise(async (resolve, reject) => {
        const { authManager } = store.getState();
        const { userTrackSettings } = { ...authManager };

        createLocalTracks({
            devices,
            facingMode: 'user'
        })
            .then(async localTracks => {
                const localVideoTrack = localTracks.find(track => track.getType() === 'video');
                const localAudioTrack = localTracks.find(track => track.getType() === 'audio');

                if (localVideoTrack && userTrackSettings?.video_muted) {
                    localVideoTrack.mute();
                }

                if (localAudioTrack && userTrackSettings?.audio_muted) {
                    localAudioTrack.mute();
                }

                await getDevices();

                store.dispatch({
                    type: videoModuleActions.SET_USER_LOCAL_TRACKS,
                    tracks: {
                        audio: localAudioTrack,
                        video: localVideoTrack
                    }
                });

                resolve();
            })
            .catch(e => {
                console.error(e);
                store.dispatch({
                    type: videoModuleActions.SET_USER_LOCAL_TRACKS_ERROR
                });
                reject(e);
            });
    });
};

/**
 * Initializes Jitsi scripts ( library, jquery and everything needed for it to run )
 * Loads available devices
 * Handles permission denied for devices
 * @return {void}
 */
const videoScriptsInit = async () => {
    store.dispatch({ type: videoModuleActions.START_FETCHING_VIDEO_SCRIPTS });
    let devices = [];
    let videoDevicePermissionGranted = false;
    let audioDevicePermissionGranted = false;

    try {
        try {
            await loadJitsiScript();
        } catch (e) {
            store.dispatch({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: {
                    code: 'generalError',
                    detail: 'There was an error when loading video script.'
                }
            });
        }

        await getDevices();

        devices = await devicesAvailable();

        audioDevicePermissionGranted = await window.JitsiMeetJS.mediaDevices.isDevicePermissionGranted(
            'audio'
        );

        videoDevicePermissionGranted = await window.JitsiMeetJS.mediaDevices.isDevicePermissionGranted(
            'video'
        );

        // TODO: antonio: ovdje puca kad vise browsera koristi kameru
        await getAndSetLocalTrack(devices);

        store.dispatch({ type: videoModuleActions.FETCHING_VIDEO_SCRIPTS_SUCCESSFUL });
    } catch (e) {
        if (e?.name === 'gum.general') {
            store.dispatch({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: {
                    code: 'videoModule.general',
                    detail: e?.message
                }
            });
        }
        if (e?.name === 'gum.permission_denied') {
            store.dispatch({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: {
                    code: 'videoModule.permissionsDenied'
                }
            });

            if (!audioDevicePermissionGranted && devices.includes('audio')) {
                const audioIndex = devices.findIndex(item => item === 'audio');

                devices.splice(audioIndex, 1);
            }

            if (!videoDevicePermissionGranted && devices.includes('video')) {
                const videoIndex = devices.findIndex(item => item === 'video');

                devices.splice(videoIndex, 1);
            }

            await getAndSetLocalTrack(devices);
        }

        store.dispatch({ type: videoModuleActions.FETCHING_VIDEO_SCRIPTS_FAILED });
    }
};

/**
 * Mutes local track
 * @param {Object} track - track to mute/unmute
 * @return {Boolean}
 */
const toggleTrack = async (track = null, mute = null) => {
    const { event, conference } = store.getState();
    const { isEventChallengeWithVideoActive = false } = { ...event };
    const { localTracks } = { ...conference };

    if (typeof track === 'string') {
        track = localTracks?.[track];
    }

    if (isEventChallengeWithVideoActive && mute === null) {
        return store.dispatch({
            type: notificationManagerActions.SET_FLOATING_NOTIFICATION,
            response: {
                code: 'videoModule.cameraAlreadyUsed'
            }
        });
    }

    if (!track) {
        return false;
    }

    if (track) {
        const isMuted = track.isMuted();

        if (mute !== null) {
            if (mute === true) {
                await track.mute();
                store.dispatch({
                    type: authActions.START_UPDATE_USER_TRACK_SETTINGS,
                    trackType: track.getType(),
                    value: true
                });

                return true;
            }

            await track.unmute();
            store.dispatch({
                type: authActions.START_UPDATE_USER_TRACK_SETTINGS,
                trackType: track.getType(),
                value: false
            });

            return true;
        }

        if (isMuted) {
            await track.unmute();
            store.dispatch({
                type: authActions.START_UPDATE_USER_TRACK_SETTINGS,
                trackType: track.getType(),
                value: false
            });
        } else {
            await track.mute();
            store.dispatch({
                type: authActions.START_UPDATE_USER_TRACK_SETTINGS,
                trackType: track.getType(),
                value: true
            });
        }

        return !isMuted;
    }

    return true;
};

/**
 * Changes local audio track
 * @param deviceID
 * @returns {Promise<void>}
 */
const changeAudioDevice = async (deviceID, room) => {
    const state = store.getState();
    const { conference, authManager } = { ...state };
    const { localTracks } = { ...conference };
    const {
        userTrackSettings: { audio_muted: audioPreviouslyMuted = false }
    } = { ...authManager };

    const newTracks = await window.JitsiMeetJS.createLocalTracks({
        devices: ['audio'],
        micDeviceId: deviceID
    });

    const localAudioTrack = newTracks.find(track => track.getType() === 'audio');

    if (audioPreviouslyMuted) {
        localAudioTrack.mute();
    }

    store.dispatch({
        type: videoModuleActions.UPDATE_USER_LOCAL_TRACK,
        trackType: 'audio',
        track: localAudioTrack
    });

    if (room) {
        await room.replaceTrack(localTracks?.audio, localAudioTrack);
    }
};

/**
 * Changed local video track
 * @param deviceID
 * @returns {Promise<void>}
 */
const changeVideoDevice = async (deviceID, room) => {
    const state = store.getState();
    const { conference, authManager } = { ...state };
    const { localTracks } = { ...conference };
    const {
        userTrackSettings: { video_muted: videoPreviouslyMuted = false }
    } = { ...authManager };

    const newTracks = await window.JitsiMeetJS.createLocalTracks({
        devices: ['video'],
        cameraDeviceId: deviceID,
        facingMode: 'user'
    });

    const localVideoTrack = newTracks.find(track => track.getType() === 'video');

    if (videoPreviouslyMuted) {
        localVideoTrack.mute();
    }

    store.dispatch({
        type: videoModuleActions.UPDATE_USER_LOCAL_TRACK,
        trackType: 'video',
        track: localVideoTrack
    });

    if (room) {
        await room.replaceTrack(localTracks?.video, localVideoTrack);
    }
};

/**
 * Jitsi proxy connection
 * @param domain {String}
 * @param room {String}
 * @param config {Object}
 * @return {Promise<unknown>}
 */
const connectToJitsi = async ({ domain, room, config }) => {
    const connectionConfig = Object.assign({}, config);
    const serviceUrl = connectionConfig.bosh || connectionConfig.websocket;

    let url = connectionConfig.bosh ? `${domain}/${serviceUrl}` : `${serviceUrl}`;
    url += `?room=${room}`;

    connectionConfig.serviceUrl = url;

    const connection = new window.JitsiMeetJS.JitsiConnection(null, null, {
        serviceUrl: connectionConfig?.serviceUrl,
        hosts: connectionConfig?.hosts,
        websocketKeepAlive: 1000,
        enableLipSync: false,
        disableDeepLinking: true,
        enableNoisyMicDetection: false
    });

    return new Promise((resolve, reject) => {
        const onConnectionSuccess = () => {
            connection.removeEventListener(
                window.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
                onConnectionSuccess
            );
            return resolve(connection);
        };

        const onConnectionFailed = err => {
            connection.removeEventListener(
                window.JitsiMeetJS.events.connection.CONNECTION_FAILED,
                onConnectionFailed
            );

            return reject(err);
        };

        connection.addEventListener(
            window.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            onConnectionSuccess
        );

        connection.addEventListener(
            window.JitsiMeetJS.events.connection.CONNECTION_FAILED,
            onConnectionFailed
        );

        connection.connect();
    });
};

/**
 * Join Room
 * @param connection {Object}
 * @param room {String}
 * @return {Promise<unknown>}
 */
const joinRoom = async ({ connection, room, config, userData }) => {
    const gameRoom = connection.initJitsiConference(room, {
        ...config,
        openBridgeChannel: true
    });

    gameRoom.setReceiverVideoConstraint(720);
    gameRoom.setSenderVideoConstraint(720);

    store.dispatch({ type: videoModuleActions.SET_GAME_ROOM_DATA, payload: gameRoom });

    await gameRoom.setDisplayName(JSON.stringify(userData));

    return new Promise(resolve => {
        const onConferenceJoin = () => {
            gameRoom.off(window.JitsiMeetJS.events.conference.CONFERENCE_JOINED, onConferenceJoin);
            return resolve(gameRoom);
        };

        gameRoom.on(window.JitsiMeetJS.events.conference.CONFERENCE_JOINED, onConferenceJoin);

        gameRoom.join();
    });
};

/**
 * Join Room
 * @param connection {Object}
 * @param room {String}
 * @return {Promise<unknown>}
 */
const removeTrack = async (roomData, track, trackType, userID) => {
    await toggleTrack(track, true);

    try {
        await roomData.removeTrack(track);

        store.dispatch({
            type: videoModuleActions.REMOVE_USER_TRACKS,
            userID,
            self: true,
            trackType
        });
    } catch {
        store.dispatch({
            type: videoModuleActions.REMOVE_USER_TRACKS,
            userID,
            self: true,
            trackType
        });
    }
};

/**
 * Join Room
 * @param connection {Object}
 * @param room {String}
 * @return {Promise<unknown>}
 */
const addTrack = async (roomData, track, trackType, userID) => {
    try {
        await roomData.addTrack(track);

        store.dispatch({
            type: videoModuleActions.ADD_USER_TRACKS,
            userID,
            track,
            self: true,
            trackType
        });

        return Promise.resolve();
    } catch (e) {
        return e;
    }
};

const tryStopTrack = track => {
    try {
        if (track) {
            track.stop();
            track = null;
        }
    } catch (e) {
        //
    }
};

export {
    loadJitsiScript,
    devicesAvailable,
    videoScriptsInit,
    getAndSetLocalTrack,
    toggleTrack,
    getDevices,
    changeAudioDevice,
    changeVideoDevice,
    connectToJitsi,
    joinRoom,
    removeTrack,
    addTrack,
    tryStopTrack
};
