import { createSelector } from 'reselect';
import qs from 'qs';
import base64 from 'base-64';
import isBase64 from 'is-base64';

export const getUrlQueryParams = state => {
    const storedUrlQueryParams = state.routerManager.urlQueryParams;
    const urlParams = isBase64(storedUrlQueryParams.substr(1))
        ? storedUrlQueryParams.substr(1)
        : '';
    //  Decode it from base64 to object
    const base64DecodedUrlParams = base64.decode(urlParams);
    const paramsObject = qs.parse(base64DecodedUrlParams, {
        ignoreQueryPrefix: true
    });
    return paramsObject;
};

export const isStressTestRequested = state => state?.routerManager?.stress_test || false;

export default createSelector([getUrlQueryParams]);
