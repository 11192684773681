import { useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getResourceByID } from 'erpcore/utils/ResourceManager/ResourceManager.selectors';
import {
    getIsErpUserDataRetrieved,
    isUserAdmin
} from 'erpcore/utils/ErpUserManager/ErpUserManager.selectors';
import { getActiveTeam, getActiveUser } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';
import { actions as resourceActions } from 'erpcore/utils/ResourceManager/ResourceManager.reducer';
import { getRouteIncludedString } from 'erpcore/utils/entityUtils';

/**
 *
 * !!!!!!!! IMPORTANT:
 * !!!!!!!! IMPORTANT:
 * !!!!!!!! IMPORTANT:
 *
 * This Component will change state.authManager.activeTeam, only for participant, and only if activeTeam is already set.
 * There are many other places in code that manage rest of the activeTeam logic. (Sto je tu je)
 */
const ParticipantAndActiveTeamSync = () => {
    const dispatch = useDispatch();
    const isErpUserDataRetrieved = useSelector(getIsErpUserDataRetrieved);
    const isAdmin = useSelector(isUserAdmin);
    const activeUser = useSelector(getActiveUser);
    const userResource = useSelector(state => getResourceByID(state, activeUser?.id));
    const userTeamResource = useSelector(state =>
        getResourceByID(state, userResource?.event_team?.id)
    );
    const activeTeam = useSelector(getActiveTeam);

    const userTeamId = userTeamResource?.id;
    const userTeamHash = userTeamResource?.hash;
    const activeTeamId = activeTeam?.id;

    const meAndTeamFetchTriggered = useRef(false);

    const fetchMe = useCallback(
        () =>
            new Promise((resolve, reject) => {
                dispatch({
                    type: resourceActions.FETCH_SINGLE_RESOURCE_START,
                    promise: { resolve, reject },
                    id: 'api/event-participant/me',
                    params: {
                        include: getRouteIncludedString('me')
                    },
                    headers: {
                        Accept: 'application/vnd.api+json'
                    },
                    ignoreIncluded: false
                });
            }).then(response => {
                meAndTeamFetchTriggered.current =
                    response?.data?.relationships?.event_team?.data?.id;
            }),
        []
    );

    const getNormalizedFalsy = useCallback(input => {
        return input || false;
    }, []);

    useEffect(() => {
        if (
            isErpUserDataRetrieved &&
            !isAdmin &&
            !!userTeamId &&
            !!activeTeamId && // change only if there is already active team (other components handle the rest)
            userTeamId !== activeTeamId
        ) {
            dispatch({
                type: authActions.STORE_ACTIVE_TEAM_DATA,
                response: {
                    hash: userTeamHash,
                    id: userTeamId
                }
            });
        }
    }, [isErpUserDataRetrieved, activeTeamId, !isAdmin, userTeamId, userTeamHash, activeUser?.id]);

    useEffect(() => {
        // fetch /me data when participant data changes
        if (
            isErpUserDataRetrieved &&
            !isAdmin &&
            !!activeUser?.id &&
            userResource?.event_team?.id &&
            getNormalizedFalsy(userResource?.event_team?.id) !==
                getNormalizedFalsy(meAndTeamFetchTriggered?.current)
        ) {
            // /me?.sdc
            // fetch if there is an activeUser?.id initially (when there may or may not be active team), and on every team id change
            meAndTeamFetchTriggered.current = userResource?.event_team?.id;
            fetchMe();
        }
    }, [isErpUserDataRetrieved, isAdmin, activeUser?.id, userResource?.event_team?.id]);

    useEffect(() => {
        if (!userResource?.id && activeUser?.id && !isAdmin && isErpUserDataRetrieved) fetchMe();
    }, [userResource?.id, activeUser?.id, isAdmin, isErpUserDataRetrieved]);

    return null;
};

export default ParticipantAndActiveTeamSync;
