import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import icons from './svgManifest';
import './Svg.scss';

const Svg = ({ icon, iconColor, className }) => {
    if (icon && icon !== '')
        return (
            <SVG
                className={`svg ${iconColor ? `svg--${iconColor}` : ''} ${className}`}
                src={icons[icon]}
                cacheGetRequests
                cacheRequests
            />
        );
    return null;
};

Svg.defaultProps = {
    className: '',
    iconColor: null
};

Svg.propTypes = {
    icon: PropTypes.string.isRequired,
    className: PropTypes.string,
    iconColor: PropTypes.string
};

export default Svg;
