import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import restClient from 'erpcore/api/restClient';
import moment from 'moment-timezone';
import random from 'lodash/random';
import { actions as ServerTimeManagerActions } from 'erpcore/utils/ServerTimeManager/ServerTimeManager.reducer';

const serverTimeFetchIntervalInMinutes = random(3.2, 4.5, true);
const MILLISECONDS_IN_MINUTES = 60000; // constant, don't edit
const SERVER_TIME_FETCH_INTERVAL_IN_MILLISECONDS =
    serverTimeFetchIntervalInMinutes * MILLISECONDS_IN_MINUTES; // derived constant, don't edit

const ServerTimeManager = () => {
    const dispatch = useDispatch();
    const intervalRef = useRef(null);

    const setServerTimeDifference = serverTime => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: ServerTimeManagerActions.SET_SERVER_TIME_DIFFERENCE,
                serverDiffFromLocal: serverTime ? +moment(serverTime) - +new Date() : 0
            });
        }).catch(error => ({ error }));
    };

    const getServerTime = useCallback(() => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: ServerTimeManagerActions.STARTED_FETCHING_SERVER_TIME
            });
            restClient
                .get('/api/server-time')
                .then(({ data }) => {
                    const { serverTime } = { ...data?.data };
                    if (serverTime) {
                        resolve(serverTime);
                    } else {
                        reject();
                    }
                })
                .catch(() => {
                    reject();
                });
        });
    }, []);

    useEffect(() => {
        getServerTime().then(setServerTimeDifference);

        intervalRef.current = setInterval(() => {
            getServerTime().then(setServerTimeDifference);
        }, SERVER_TIME_FETCH_INTERVAL_IN_MILLISECONDS);

        return () => {
            clearInterval(intervalRef.current);
        };
    }, []);

    return null;
};

export default ServerTimeManager;
