import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getResourceByID } from 'erpcore/utils/ResourceManager/ResourceManager.selectors';

const PeopleName = ({ iri }) => {
    const personData = useSelector(state => getResourceByID(state, iri));

    return `${personData?.first_name || ''} ${personData?.last_name || ''}`;
};

PeopleName.defaultProps = {
    iri: null
};

PeopleName.propTypes = {
    iri: PropTypes.string
};

export default PeopleName;
