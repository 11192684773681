import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

// Notifications
import getDefaultNotifications from './notifications/default';
import getAuthNotifications from './notifications/auth';
import getListingNotifications from './notifications/listing';
import getMediaNotifications from './notifications/media';
import getVideoModuleNotifications from './notifications/videoModule';
import getHelpNotifications from './notifications/help';
import getEventNotifications from './notifications/event';
import getTeamNotifications from './notifications/team';

/**
 * Notification Manager
 * @return {Node} Returns Notification component
 */
const NotificationManager = ({ code, identifier, detail, notificationData }) => {
    const forwardData = { code, identifier, detail, notificationData };

    // Default
    const defaultNotifications = getDefaultNotifications(forwardData);
    if (defaultNotifications) {
        return defaultNotifications;
    }

    // Auth
    const authNotifications = getAuthNotifications(forwardData);
    if (authNotifications) {
        return authNotifications;
    }

    // Listing
    const listingNotifications = getListingNotifications(forwardData);
    if (listingNotifications) {
        return listingNotifications;
    }

    // Media
    const mediaNotifications = getMediaNotifications(forwardData);
    if (mediaNotifications) {
        return mediaNotifications;
    }

    // Video Module
    const videoModuleNotifications = getVideoModuleNotifications(forwardData);
    if (videoModuleNotifications) {
        return videoModuleNotifications;
    }

    // Help
    const helpNotifications = getHelpNotifications(forwardData);
    if (helpNotifications) {
        return helpNotifications;
    }

    // Event
    const eventNotifications = getEventNotifications(forwardData);
    if (eventNotifications) {
        return eventNotifications;
    }

    // Team
    const teamNotifications = getTeamNotifications(forwardData);
    if (teamNotifications) {
        return teamNotifications;
    }

    return (
        <Notification
            identifier={identifier}
            title="Warning"
            text={
                <Fragment>
                    <strong>{code}</strong> - This is the message missing Notification case!
                    {!!detail && (
                        <Fragment>
                            <br />
                            {detail}
                        </Fragment>
                    )}
                </Fragment>
            }
            type="warning"
        />
    );
};

NotificationManager.defaultProps = {
    code: null,
    identifier: null,
    detail: null,
    notificationData: null
};

NotificationManager.propTypes = {
    code: PropTypes.string,
    identifier: PropTypes.string,
    detail: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    notificationData: PropTypes.oneOfType([PropTypes.object])
};

export default NotificationManager;
