import unionWith from 'lodash/unionWith';
import Jsona from 'jsona';
import { getForbiddenChildrenRelationshipKeys } from 'erpcore/utils/entityUtils';

const dataFormatter = new Jsona();

/**
 * Get included data in formFormated DTO
 * @param {object} iri
 * @param {object} includedData
 * @returns {object}
 */
export const getIncludedDataByIri = (iri, includedData) => {
    if (includedData && includedData.included && iri) {
        return includedData.included.find(include => include.iri === iri) || {};
    }

    return {};
};

/**
 * get id From iri
 * @param {string} iri
 * @returns {string}
 */
export const getIdFromIri = iri => {
    if (!iri) {
        return null;
    }
    // Assuming the id value is the last part after the /
    const n = iri.lastIndexOf('/');
    const id = iri.substring(n + 1);

    return id;
};

/**
 * Prepare data for included from select
 * @param {object} data
 * @param {object} included
 * @returns {object}
 */
export const formatSelectDataForIncluded = (data, included) => {
    const includedData = [];
    data.map(item => {
        const includedDataObject = {};
        includedDataObject.iri = item.value;
        includedDataObject.label = item.label;
        includedData.push(includedDataObject);
        return true;
    });

    const mergedIncludes = unionWith(included, includedData, (a, b) => a.iri === b.iri);

    return mergedIncludes;
};

const cleanUpData = (data, ignoreIncluded, removeForbiddenRelationships = true) => {
    const dataCopy = Array.isArray(data) ? [...data] : { ...data };

    const forbiddenChildrenRelationships = removeForbiddenRelationships
        ? getForbiddenChildrenRelationshipKeys(data?.data?.type)
        : [];

    // early return original data (shallow copied) if type doesn't have forbidden relationship types and includes should not be ignored
    if (!forbiddenChildrenRelationships?.length && !ignoreIncluded) {
        return dataCopy;
    }

    /**
     * handle array
     */
    if (Array.isArray(dataCopy)) {
        return dataCopy.reduce((accumulator, currentValue) => {
            const newCurrent = { ...currentValue };
            // remove included
            if (ignoreIncluded) {
                delete newCurrent.included;
            }
            // remove forbidden relationships
            if (forbiddenChildrenRelationships?.length) {
                forbiddenChildrenRelationships.forEach(forbiddenKey => {
                    delete currentValue?.data?.relationships[forbiddenKey];
                });
            }
            return [...accumulator, newCurrent];
        }, []);
    }

    /**
     * handle single
     */

    // remove included
    if (ignoreIncluded) {
        delete dataCopy.included;
    }

    // remove forbidden relationships
    if (forbiddenChildrenRelationships?.length) {
        forbiddenChildrenRelationships.forEach(forbiddenKey => {
            delete dataCopy?.data?.relationships?.[forbiddenKey];
        });
    }

    return dataCopy;
};

/**
 * Transferring data from the API to Frontend (and Form) friendly format
 * @param data
 * @param ignoreIncluded
 * @param removeForbiddenRelationships
 * @return {TJsonaModel | Array<TJsonaModel>}
 */
const dto = (data, ignoreIncluded, removeForbiddenRelationships = true) => {
    const processedData = cleanUpData(data, ignoreIncluded, removeForbiddenRelationships);

    return dataFormatter.deserialize(processedData);
};

export default dto;
