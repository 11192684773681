import React, { useCallback, useMemo } from 'react';
import Modal from 'erpcore/components/Modal';
import moment from 'moment';
import { timeValueConverter } from 'erpcore/utils/utils';
import Notification from 'erpcore/components/Notification';
import { getModal } from 'erpcore/utils/LayoutManager/LayoutManager.selectors';
import { getParticipantNotifications } from 'erpcore/utils/NotificationManager/NotificationManager.selectors';
import { useDispatch, useSelector } from 'react-redux';

import { actions as layoutManagerActions } from 'erpcore/utils/LayoutManager/LayoutManager.reducer';
import './NotificationsLogModal.scss';

const renderIcon = type => {
    if (type.includes('remaining-time-notification')) return 'alertBell';
    if (type.includes('left the call') || type.includes('joined the call')) return 'usersOnline';
    return true;
};

const modalId = 'NotificationsLog';

const NotificationsLogModal = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(getParticipantNotifications);
    const modalOpened = useSelector(state => getModal(state, modalId));

    const closeModal = useCallback(() => {
        dispatch({
            type: layoutManagerActions.CLOSE_MODAL,
            id: modalId
        });
    }, []);

    const sortedNotifications = useMemo(() => {
        const notificationsClone = Array.from(notifications);

        return notificationsClone.sort(({ timeStamp: timeStampA }, { timeStamp: timeStampB }) => {
            return moment(timeStampB) - moment(timeStampA);
        });
    }, [notifications]);

    // useEffect(() => {
    //     // Before sending eventNotificaitons to storeNotificationLog first check if they should fire or not
    //     // Refer to Event.jsx useEffect /line:135
    //
    //     storeNotificationLog(eventNotifications);
    // }, [eventNotifications]);

    return (
        <Modal
            id={modalId}
            className="notifications-log__modal"
            onClose={closeModal}
            title="Notifications"
            transparentOverlayMobile
            blurMobile={false}
        >
            {modalOpened && (
                <>
                    {sortedNotifications?.map(notification => {
                        const { timeStamp, type, time } = { ...notification };

                        if (type === 'AT_THE_END') {
                            return null;
                        }

                        const timeDifference = moment().diff(moment(timeStamp));

                        const coordinatorNotification = !!(
                            type !== 'BEFORE_END' && type !== 'AFTER_START'
                        );

                        return (
                            <Notification
                                key={notification.identifier + timeStamp}
                                title={notification?.title || notification?.content}
                                text={notification?.content ? notification.content : ''}
                                origin=""
                                type="card"
                                icon={renderIcon(notification.identifier)}
                                className={notification?.title ? 'participant-custom-title' : ''}
                            >
                                {coordinatorNotification && timeStamp && (
                                    <p className="notification__time">
                                        {timeValueConverter(timeDifference / 1000)} ago
                                    </p>
                                )}

                                {!coordinatorNotification && timeStamp && (
                                    <p className="notification__time">
                                        Occurred {timeValueConverter(time)}{' '}
                                        {type === 'AFTER_START'
                                            ? 'after event started.'
                                            : 'before the event ends.'}
                                    </p>
                                )}
                            </Notification>
                        );
                    })}
                </>
            )}
        </Modal>
    );
};

NotificationsLogModal.defaultProps = {};

NotificationsLogModal.propTypes = {};

export default NotificationsLogModal;
