export const getActiveUser = state => state?.authManager?.activeUser || state?.erpUserManager?.data;

export const getActiveEvent = state => state?.authManager?.activeEvent;

export const getActiveTeam = state => state?.authManager?.activeTeam;

export const getSignedIn = state =>
    !!state?.authManager?.activeUser || !!state.erpUserManager?.data;

export const getUserTrackSettings = state => state.authManager?.userTrackSettings || null;
