import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getMercureNotifications,
    getResourceByID
} from 'erpcore/utils/ResourceManager/ResourceManager.selectors';
import moment from 'moment';
import PeopleName from 'erpcore/components/PeopleName';
import { getActiveEvent, getActiveUser } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { getResponsive } from 'erpcore/utils/utils';
import {
    getEventNotifications,
    getParticipantNotifications
} from 'erpcore/utils/NotificationManager/NotificationManager.selectors';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';

const MercureNotifications = () => {
    const dispatch = useDispatch();
    const [notificationsFetching, setNotificationsFetching] = useState(false);
    const activeEvent = useSelector(getActiveEvent);
    const mercureNotifications = useSelector(getMercureNotifications);
    const eventResource = useSelector(state => getResourceByID(state, activeEvent?.id));
    const participantNotifications = useSelector(getParticipantNotifications);
    const activeUser = useSelector(getActiveUser);
    const notifications = useSelector(getEventNotifications);
    const { isMobile } = getResponsive();
    const [isLandscape, setLandscape] = useState(false);
    const notificationsTimeoutTracker = useRef({});

    const fetchedEventIdRef = useRef(eventResource?.id);
    const additionalTimeRef = useRef(eventResource?.additional_time);
    const pausedRef = useRef(eventResource?.paused);

    const addCustomNotification = useCallback(
        notificationData =>
            dispatch({
                type: notificationManagerActions.ADD_EVENT_NOTIFICATION,
                response: notificationData
            }),
        []
    );

    const handleOrientationChange = useCallback(() => {
        // set landscape only if window height increased by 30% is still smaller than window width
        setLandscape(1.3 * window.innerHeight < window.innerWidth);
    }, []);

    const fetchERPNotifications = useCallback(async () => {
        try {
            const data = await restClient.get(
                `/api/notifications?filters[event][equals]=${activeEvent?.id}&pagination=false`
            );

            return Promise.resolve(data.data);
        } catch (e) {
            return Promise.reject(e);
        }
    }, [activeEvent]);

    const fetchEventNotifications = useCallback(async () => {
        try {
            const data = await restClient.get(`${activeEvent?.id}?include=notifications`);
            const dataDTO = dto(data?.data);

            if (dataDTO?.notifications) {
                dataDTO.notifications.forEach(notification => {
                    dispatch({
                        type: notificationManagerActions.ADD_EVENT_NOTIFICATION,
                        response: {
                            id: notification?.id,
                            content: notification?.content,
                            time: notification?.time,
                            _type: notification?._type,
                            title: '',
                            description: '',
                            iconColor: '',
                            duration: 0,
                            display: false,
                            type: notification?.type,
                            timeStamp: notification?.timestamp
                        }
                    });
                });
            }

            return Promise.resolve(dataDTO?.notifications);
        } catch (e) {
            return Promise.reject(e);
        }
    }, [activeEvent]);

    const addParticipantNotification = useCallback(notificationData => {
        dispatch({
            type: notificationManagerActions.SET_PARTICIPANT_NOTIFICATION,
            response: notificationData
        });
    }, []);

    const handleNotificationCreate = useCallback(
        async ({ identifier, id, content, _type, itShouldDisplayAt, time }) => {
            const timeRemaining = itShouldDisplayAt.diff(moment(new Date()), 'seconds');

            // Sets timeout on when the notification should fire based on current time/duration of the event
            // This resets everytime notification array changes, and sets new timeout
            if (timeRemaining > 0 && !notificationsTimeoutTracker.current[id]) {
                notificationsTimeoutTracker.current[id] = setTimeout(() => {
                    // Show notification
                    dispatch({
                        type: notificationManagerActions.SHOW_EVENT_NOTIFICATION,
                        identifier
                    });

                    // Add this notification to participant array object for notification logs
                    addParticipantNotification({
                        id: identifier || id,
                        content,
                        type: _type,
                        time,
                        title: 'Event notification',
                        timeStamp: moment().toISOString()
                    });
                }, timeRemaining * 1000);
            }
        },
        [notifications]
    );

    const storeNotificationLog = useCallback(notificationsData => {
        const filteredNotifications = notificationsData?.map(
            ({
                identifier,
                id,
                attributes,
                message: notificationMessage = null,
                extra,
                content,
                timestamp,
                _type,
                time
            }) => {
                const message = attributes?.content || notificationMessage || content;

                const { extra: { type } = {} } = {
                    ...attributes
                };
                let title = 'Event notification';

                if (
                    type === 'all' ||
                    extra?.type === 'all' ||
                    type === 'team' ||
                    extra?.type === 'team'
                )
                    title = `Notification from your Event Coordinator`;

                return {
                    id: identifier || id,
                    content: message,
                    type: type || _type,
                    time,
                    title,
                    timeStamp: attributes?.created_at
                        ? moment(attributes?.created_at).toISOString()
                        : moment(timestamp).toISOString()
                };
            }
        );

        if (filteredNotifications?.length) {
            dispatch({
                type: notificationManagerActions.SET_PARTICIPANT_NOTIFICATION,
                response: filteredNotifications
            });
        }
    }, []);

    /**
     * Fetch EventNotifications from API
     * @type {function(): Promise<boolean|*|undefined>}
     */
    const fetchNotifications = useCallback(async () => {
        setNotificationsFetching(true);

        try {
            if (participantNotifications?.length) {
                dispatch({
                    type: notificationManagerActions.CLEAR_PARTICIPANT_NOTIFICATIONS
                });
            }

            const [ERPNotification, eventNotification] = await Promise.all([
                fetchERPNotifications(),
                fetchEventNotifications()
            ]);

            const mergedNotifications = [
                ...(ERPNotification?.data?.filter(
                    notification => notification?.attributes?.content !== 'kickout' // don't display kickout notifications
                ) || []),
                ...(eventNotification?.filter(
                    notification => moment(notification?.timestamp) < moment(new Date())
                ) || [])
            ];

            storeNotificationLog(mergedNotifications);
            setNotificationsFetching(false);
            return true;
        } catch (e) {
            return e;
        }
    }, [storeNotificationLog, fetchERPNotifications, fetchEventNotifications]);

    useEffect(() => {
        const latestNotification = mercureNotifications?.[mercureNotifications?.length - 1] || null;

        if (latestNotification) {
            const message =
                latestNotification?.extra?.exclude !== activeUser?.id
                    ? latestNotification?.message
                    : null;

            if (message) {
                const senderId = latestNotification?.extra?.exclude;

                const type = latestNotification?.extra?.type;

                const title = (
                    <>
                        Notification from <PeopleName iri={senderId} />
                        {type === 'all' && ' to all teams'}
                    </>
                );

                addCustomNotification({
                    id: message,
                    content: message,
                    time: 0,
                    _type: 'BEFORE_END',
                    type: '',
                    title,
                    description: '',
                    iconColor: '',
                    duration: 0,
                    display: true,
                    origin: 'Mercure',
                    timeStamp: moment().toISOString()
                });

                addParticipantNotification({
                    id: message,
                    content: message,
                    time: 0,
                    _type: 'BEFORE_END',
                    type: '',
                    title: 'Notification from your Event Coordinator',
                    description: '',
                    iconColor: '',
                    duration: 0,
                    display: false,
                    origin: 'Mercure',
                    timeStamp: moment().toISOString()
                });
            }
        }
    }, [mercureNotifications]);

    /**
     * Adds remaining time selector automatically when event started
     */
    useEffect(() => {
        const findMatch = notifications.find(({ id }) => id === 'remaining-time-notification');
        const { identifier } = { ...findMatch };
        if (
            eventResource?.started_at &&
            eventResource?.duration &&
            !eventResource?.ended_at &&
            !identifier
        )
            addCustomNotification({
                id: 'remaining-time-notification',
                content: 'Time Remaining is less than 2 minutes.',
                time: 120,
                _type: '',
                title: '',
                description: '',
                iconColor: '',
                duration: 0,
                display: false,
                type: '',
                timeStamp: moment().toISOString()
            });
    }, [eventResource, notifications]);

    const getNormalizedAdditionalTime = useCallback(input => {
        return input || 0;
    }, []);

    const getNormalizedPause = useCallback(input => {
        return input || false;
    }, []);

    const syncEventRefs = useCallback(({ id, additional_time, paused }) => {
        fetchedEventIdRef.current = id;
        additionalTimeRef.current = additional_time;
        pausedRef.current = paused;
    }, []);

    /**
     * Keep notifications in sync
     * When event is paused or additional time is added, refetch event notifications and sync with redux
     * This is done to get correct timestamps on when the notification should occur
     */
    useEffect(() => {
        if (eventResource?.id !== fetchedEventIdRef.current) {
            // will not fetch notifications if this useEffect is triggered by event data becoming available
            syncEventRefs({ ...eventResource });
        }

        if (
            getNormalizedAdditionalTime(eventResource?.additional_time) !==
                getNormalizedAdditionalTime(additionalTimeRef?.current) ||
            getNormalizedPause(eventResource?.paused) !== getNormalizedPause(pausedRef?.current)
        ) {
            fetchNotifications();
        }

        syncEventRefs({ ...eventResource });
    }, [eventResource?.id, eventResource?.additional_time, eventResource?.paused]);

    /**
     * When orientation changes, add notification about portrait mode
     */
    useEffect(() => {
        const findMatch = notifications.find(({ id }) => id === 'landscape-notification');
        const { identifier, display } = { ...findMatch };

        if (isLandscape && isMobile) {
            if (identifier && !display)
                dispatch({
                    type: notificationManagerActions.SHOW_EVENT_NOTIFICATION,
                    identifier
                });
            else
                addCustomNotification({
                    id: 'landscape-notification',
                    content: 'For better user experience, please use portrait mode.',
                    time: 0,
                    _type: '',
                    title: '',
                    description: '',
                    iconColor: '',
                    duration: 0,
                    display: true,
                    type: '',
                    timeStamp: moment().toISOString()
                });
        } else
            dispatch({
                type: notificationManagerActions.REMOVE_NOTIFICATION,
                identifier
            });
    }, [isLandscape, isMobile]);

    /**
     * Handles notification after orientation change
     */
    useEffect(() => {
        window.addEventListener('resize', handleOrientationChange);

        return () => window.removeEventListener('resize', handleOrientationChange);
    }, []);

    /**
     * Fetch notifications to use them in participant notification log after event started
     * These notifications are notifications sent from coordinator to a team or to all teams
     */
    useEffect(() => {
        if (eventResource?.started_at) fetchNotifications();
    }, [eventResource?.started_at, fetchNotifications]);

    useEffect(() => {
        const { started_at, duration, paused, remaining_time: remainingTime } = {
            ...eventResource
        };

        if (started_at && duration && remainingTime) {
            // If event paused, stop all current timers
            if (paused || notificationsFetching) {
                const timers = Object.keys(notificationsTimeoutTracker.current);

                timers.forEach(timer => clearTimeout(notificationsTimeoutTracker.current?.[timer]));

                notificationsTimeoutTracker.current = {};
            } else {
                notifications.forEach(notification => {
                    // Something important for timers changed
                    // Reset all timers to set new ones
                    if (notificationsTimeoutTracker.current?.[notification?.id]) {
                        clearTimeout(notificationsTimeoutTracker.current?.[notification?.id]);
                        notificationsTimeoutTracker.current[notification?.id] = null;
                    }

                    if (
                        (notification?._type === 'AFTER_START' ||
                            notification?._type === 'BEFORE_END') &&
                        !notification?.display &&
                        !notification?.notificationDuration
                    ) {
                        handleNotificationCreate({
                            ...notification,
                            itShouldDisplayAt: moment(notification?.timeStamp)
                        });
                    }

                    if (notification?.id === 'remaining-time-notification') {
                        const passedTime =
                            moment().diff(moment(eventResource?.started_at)) / 1000 -
                            eventResource?.total_paused_time -
                            eventResource?.additional_time;
                        const currentTime = eventResource?.duration - passedTime;

                        if (currentTime < notification?.time) {
                            addParticipantNotification({
                                id: notification?.identifier || notification?.id,
                                content: notification?.content,
                                type: notification?.type,
                                title: 'Event notification',
                                timeStamp: moment().subtract(120 - currentTime, 'seconds')
                            });
                        } else {
                            notificationsTimeoutTracker.current[notification?.id] = setTimeout(
                                () => {
                                    // Show notification
                                    dispatch({
                                        type: notificationManagerActions.SHOW_EVENT_NOTIFICATION,
                                        identifier: notification?.identifier
                                    });
                                },
                                (currentTime - 120) * 1000
                            );
                        }
                    }
                });
            }
        }
    }, [
        notifications,
        eventResource?.paused,
        eventResource?.additional_time,
        notificationsFetching
    ]);

    return null;
};

export default MercureNotifications;
