import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'erpcore/components/Modal';
import Button from 'erpcore/components/Button';
import Svg from 'erpcore/components/Svg';
import ElementLoader from 'erpcore/components/ElementLoader';
import { getActiveEvent } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import {
    getMercureErrors,
    getResourceByID
} from 'erpcore/utils/ResourceManager/ResourceManager.selectors';
import './MercureReconnectionModal.scss';

const millisecondsDelayToShowModal = 7000;
const millisecondsDelayToHideModal = 1000;

const MercureReconnectionModal = () => {
    const activeEvent = useSelector(getActiveEvent);
    const eventResource = useSelector(state => getResourceByID(state, activeEvent?.id));
    const mercureErrors = useSelector(getMercureErrors);
    const mercureErrorsRef = useRef(mercureErrors);
    const [showModal, setShowModal] = useState(false);

    const reloadPage = useCallback(() => {
        window.location.reload();
        return false;
    }, []);

    const scheduleShowModal = useCallback(() => {
        setTimeout(() => {
            // check if it is still allowed to show modal
            if (mercureErrorsRef?.current?.length) {
                setShowModal(true);
            }
        }, millisecondsDelayToShowModal);
    }, []);

    const scheduleHideModal = useCallback(() => {
        setTimeout(() => {
            if (!mercureErrorsRef?.current?.length) {
                // check if it is still allowed to hide modal
                setShowModal(false);
            }
        }, millisecondsDelayToHideModal);
    }, []);

    useEffect(() => {
        mercureErrorsRef.current = mercureErrors;

        if (mercureErrors?.length) {
            scheduleShowModal();
        } else {
            scheduleHideModal();
        }
    }, [mercureErrors]);

    if (!showModal || eventResource?.ended_at) {
        return null;
    }

    return (
        <Modal
            className="mercure-reconnection-modal"
            opened={showModal}
            title={
                <>
                    <Svg className="mercure-reconnection-modal__icon" icon="notificationError" />
                    Live game data connection lost!
                </>
            }
            onClose={() => {}}
        >
            <div className="mercure-reconnection-modal__content">
                <div className="mercure-reconnection-modal__col">
                    <p>
                        Wait for reconnection <ElementLoader />
                    </p>
                </div>
                <div className="mercure-reconnection-modal__divider">
                    <div className="mercure-reconnection-modal__divider-text">or</div>
                </div>
                <div className="mercure-reconnection-modal__col">
                    <p>
                        <Button onClick={reloadPage} label="Reload page" />
                    </p>
                </div>
            </div>
        </Modal>
    );
};

MercureReconnectionModal.defaultProps = {};

MercureReconnectionModal.propTypes = {};

export default MercureReconnectionModal;
