export const getEventData = state => state.join?.eventData;

export const getEventParticipantBy = (state, targets) =>
    state.join?.eventData?.participants?.filter(participant =>
        Object.keys(targets)
            .map(
                targetKey =>
                    participant[targetKey] &&
                    targets[targetKey] &&
                    participant[targetKey] === targets[targetKey]
            )
            .includes(true)
    );

export const getEventParticipantData = (state, participantHash) =>
    state.join?.eventData?.participants?.filter(
        participant => participantHash === participant?.hash
    )[0];

export const getEventDataFetching = state => state.join?.fetchingEventData;
