import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useIsJoinScreen = () => {
    const location = useLocation();
    const [locationPathname, setLocationPathname] = useState(location?.pathname);

    useEffect(() => {
        setLocationPathname(location?.pathname);
    }, [location?.pathname]);

    return !!(locationPathname && locationPathname.startsWith('/join'));
};

export default useIsJoinScreen;
