import { memo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveEvent } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import {
    getResourceByID,
    getRestOfDataRaw
} from 'erpcore/utils/ResourceManager/ResourceManager.selectors';
import { getErpUserData } from 'erpcore/utils/ErpUserManager/ErpUserManager.selectors';
import { actions as resourceManagerActions } from 'erpcore/utils/ResourceManager/ResourceManager.reducer';

const ManageCoordinatorChanges = () => {
    const ERPUserData = useSelector(getErpUserData);
    const activeEvent = useSelector(getActiveEvent);
    const eventResource = useSelector(state => getResourceByID(state, activeEvent?.id));
    const miscData = useSelector(getRestOfDataRaw);
    const dispatch = useDispatch();

    const updateEventResource = useCallback(formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: resourceManagerActions.UPDATE_SINGLE_RESOURCE_START,
                promise: { resolve, reject },
                formData
            });
        });
    }, []);

    const fetchEventResource = useCallback(formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: resourceManagerActions.FETCH_SINGLE_RESOURCE_START,
                promise: { resolve, reject },
                id: formData?.id,
                params: {}
            });
        });
    }, []);

    const checkIfResourceIsMissing = useCallback(
        resource => {
            if (!miscData?.[resource?.id]) {
                fetchEventResource({ id: `${resource?.id}/simple` });
            }
        },
        [miscData]
    );

    // When admins joins, check if he is already in admin lists on the event resource, or coordinators or manager list
    // If not, update event with a new admin so participants can get them trough mercure
    useEffect(() => {
        if (
            ERPUserData?.id &&
            eventResource?.id &&
            !eventResource?.admins?.some(user => user?.id === ERPUserData?.id) &&
            !eventResource?.coordinators?.some(user => user?.id === ERPUserData?.id) &&
            !eventResource?.managers?.some(user => user?.id === ERPUserData?.id) &&
            ERPUserData?.userTypes?.find(
                userType => userType?.attribute === 'CAN_USER_TYPE_OUTBACK_ADMIN'
            )
        ) {
            updateEventResource({
                id: activeEvent?.id,
                admins: [...(eventResource?.admins || []), ERPUserData?.id]
            });
        }
    }, [eventResource?.admins, eventResource?.id, ERPUserData]);

    useEffect(() => {
        if (eventResource?.admins && miscData) {
            eventResource.admins.forEach(checkIfResourceIsMissing);
        }

        if (eventResource?.coordinators && miscData) {
            eventResource.coordinators.forEach(checkIfResourceIsMissing);
        }

        if (eventResource?.managers && miscData) {
            eventResource.managers.forEach(checkIfResourceIsMissing);
        }
    }, [
        eventResource?.admins,
        eventResource?.coordinators,
        eventResource?.managers,
        checkIfResourceIsMissing
    ]);

    return null;
};

export default memo(ManageCoordinatorChanges);
