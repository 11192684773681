import React, { useEffect, useCallback, useRef } from 'react';
import InitialLoader from 'erpcore/screens/InitialLoader';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { clearToken, getToken, setToken } from 'erpcore/api';
import { actions as erpUserActions } from 'erpcore/utils/ErpUserManager/ErpUserManager.reducer';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';
import restClient from 'erpcore/api/restClient';
import { actions as routerManagerActions } from 'erpcore/utils/RouterManager/RouterManager.reducer';
import { getActiveEvent, getActiveUser } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import dto from 'erpcore/utils/dto';

/**
 * Router Manager
 * - authManager (authManager.activeUser) is persisted
 * - erpUserManager is NOT persisted
 * - get admin token from erp iframe
 * - update authManager.activeUser when erp token retrival is done
 * @return {JSX.Element} Returns current active route component
 */
const ErpUserManager = () => {
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const activeEvent = useSelector(getActiveEvent);
    const activeUser = useSelector(getActiveUser);
    const history = useHistory();
    const eventHashRef = useRef(null);
    const userHashRef = useRef(null);
    const location = useLocation();

    const getEventHash = useCallback(() => {
        return eventHashRef.current;
    }, []);

    const getUserHash = useCallback(() => {
        return userHashRef.current;
    }, []);

    // remove legacy local storage
    localStorage.removeItem('mercureToken');

    // eslint-disable-next-line no-unused-vars
    const removeActiveUser = () => {
        dispatch({
            type: authActions.STORE_ACTIVE_USER_DATA,
            response: null
        });
        dispatch({
            type: authActions.STORE_ACTIVE_TEAM_DATA,
            response: null
        });
    };

    const userIsNotErpUser = () => {
        const finalWork = () => {
            dispatch({
                type: erpUserActions.DELETE_ERP_USER_DATA
            });
        };

        clearToken();

        if (activeUser?.hash) {
            restClient
                .get(`api/event-participant/me`, {
                    headers: { 'X-AUTH-TOKEN': activeUser.hash }
                })
                .then(response => {
                    const participantData = dto(response?.data);
                    if (participantData?.hash && participantData?.id && participantData?.jwt) {
                        dispatch({
                            type: authActions.STORE_ACTIVE_USER_DATA,
                            response: {
                                hash: participantData?.hash,
                                id: participantData?.id,
                                jwt: participantData?.jwt
                            }
                        });
                    }
                    finalWork();
                })
                .catch(() => {
                    finalWork();
                });
        } else {
            finalWork();
        }

        if (activeUser && activeEvent?.hash && !getEventHash()) {
            history.push('/');
        } else {
            history.push(`/join/${getEventHash()}`);
        }
    };

    const setAdminActiveUser = useCallback(ERPUserData => {
        dispatch({
            type: authActions.STORE_ACTIVE_USER_DATA,
            response: { id: ERPUserData?.id, hash: ERPUserData?.hash }
        });
        dispatch({
            type: authActions.STORE_ACTIVE_TEAM_DATA,
            response: null
        });
    }, []);

    const fetchMeData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: authActions.START_FETCHING_ME
            });
        }).catch(error => ({ error }));
    };

    const getUserToken = useCallback(async () => {
        try {
            const userToken = await restClient.get(
                `/api/users/by-hash/${getEventHash()}/${getUserHash()}`
            );

            return userToken?.data?.token;
        } catch (e) {
            throw new Error(e);
        }
    }, []);

    const getUserData = useCallback(
        async ({ token }) => {
            restClient.defaults.headers.Authorization = `Bearer ${token}`;

            try {
                const userData = await fetchMeData();

                if (userData) {
                    dispatch({
                        type: erpUserActions.STORE_ERP_USER_DATA,
                        data: userData
                    });

                    setAdminActiveUser({ id: userData?.id, hash: userData?.hash });
                }
            } catch (e) {
                throw new Error(e);
            }
        },
        [activeEvent]
    );

    // TODO: antonio: logic of this function is flawed and has to be improved
    const checkIfUserIsERPUser = useCallback(async () => {
        // Need to store eventHash and userHash before replacing the link in the adress bar to hide user and event hashes
        if (match?.params?.eventHash) eventHashRef.current = match?.params?.eventHash;
        if (match?.params?.userHash) userHashRef.current = match?.params?.userHash;

        if (location?.search?.includes('stress_test=true')) {
            dispatch({ type: routerManagerActions.SET_IS_STRESS_TEST, response: true });
        }

        const isCoordinatorJoinRoute = match?.path && match.path.startsWith('/coordinator-join');

        const eventHash = getEventHash();
        const userHash = getUserHash();

        try {
            // If activeEvent is different then the new event hash, it means coordinator is trying to enter a different event
            // Clear all data from that previous event
            if (activeEvent?.hash && eventHash && activeEvent?.hash !== eventHash) {
                // Also clear token if event is different since the token will be different now anyways
                clearToken();
                dispatch({ type: authActions.CLEAN_ACTIVES });
            }

            // If and only if eventHash and userHash is present ( this means its a coordinator since that is the only route  with user and eventHash :names )
            if (eventHash && userHash) {
                history.replace('/');
            }

            // If token exists, try to get userData with that token, if it fails it means token is expired or invalid
            // But that doesnt yet mean the user is not valid
            if (!isCoordinatorJoinRoute && getToken()) {
                try {
                    await getUserData({ eventHash, token: getToken() });
                } catch (e) {
                    // Token is invalid or expired, try to get new one using eventHash and userHash
                    try {
                        const userToken = await getUserToken(eventHash, userHash);

                        // If user token exists, ERP user is valid, set its token and fetch its data, and proceed to /join screen as coordinator
                        if (userToken) {
                            setToken(userToken);

                            await getUserData({ eventHash, token: userToken });
                        }
                    } catch (err) {
                        // If this fails as well it means user is definitely not ERP user
                        userIsNotErpUser();
                    }
                }
            } else if (!eventHash || !userHash) {
                // If there is no userHash, it means its a normal join/:eventHash or join/:eventHash/:participantHash
                // And its definitely not ERP User since it doesnt have userHash
                userIsNotErpUser();
            } else {
                // If userHash is present and no token is present, it means coordinator is trying to join to a event
                // Try to get its token
                const userToken = await getUserToken(eventHash, userHash);
                if (userToken) {
                    setToken(userToken);

                    await getUserData({ eventHash, token: userToken });
                }
            }

            return true;
        } catch (e) {
            // If fetching token is invalid or not existent, user is a regular particpant
            userIsNotErpUser();
            return e;
        } finally {
            // Finally, when everything passes if there is no active event ( that means event is in progress if its true )
            // And if eventHash is present in URL, redirect the user to the join page
            // All the logic if the user should be joined as coordinator or user is done above already by storing ERPUser data etc...
            if (!activeEvent && eventHash) {
                history.push(`/join/${eventHash}`);
            }
        }
    }, [activeEvent]);

    useEffect(() => {
        checkIfUserIsERPUser();
    }, [activeEvent]);

    return <InitialLoader />;
};

export default ErpUserManager;
