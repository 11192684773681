export const actions = {
    START_FETCHING: 'START_FETCHING',
    FETCHING_SUCCESSFUL: 'FETCHING_SUCCESSFUL',
    FETCHING_FAILED: 'FETCHING_FAILED',
    START_FETCHING_LISTING: 'START_FETCHING_LISTING',
    // Bulk Actions
    UPDATE_BULK_ACTIONS_IRIS: 'UPDATE_BULK_ACTIONS_IRIS',
    TOGGLE_BULK_ACTIONS_MODAL: 'TOGGLE_BULK_ACTIONS_MODAL',
    SET_QUERY_PARAMS: 'SET_QUERY_PARAMS',
    START_BATCH_REQUESTS: 'START_BATCH_REQUESTS',
    BATCH_REQUESTS_SUCCESSFUL: 'BATCH_REQUESTS_SUCCESSFUL',
    BATCH_REQUESTS_FAILED: 'BATCH_REQUESTS_FAILED'
};

export const initialState = {};

export default (
    state = initialState,
    { type, response, name, params, selectAll, selectAllEntries, apiRoute }
) => {
    switch (type) {
        case actions.SET_QUERY_PARAMS: {
            return {
                ...state,
                queryParams: response
            };
        }
        case actions.START_FETCHING_LISTING: {
            return {
                ...state,
                [name]: {
                    queryParams: params,
                    bulkActions: {
                        modalOpened: false,
                        selectAll: false,
                        iris: []
                    }
                }
            };
        }
        case actions.UPDATE_BULK_ACTIONS_IRIS: {
            const newState = Object.assign({}, state[name]);

            if (!Array.isArray(response)) {
                let newIris = newState?.bulkActions?.iris.slice();
                if (newIris?.includes(response)) {
                    newIris = newIris.filter(item => item !== response);
                } else if (response) {
                    newIris.push(response);
                }
                newState.bulkActions.iris = newIris;
            } else {
                newState.bulkActions.iris = response;
            }
            newState.bulkActions.selectAll = selectAll;
            newState.bulkActions.apiRoute = apiRoute;

            if (selectAllEntries) {
                newState.bulkActions.selectAllEntries = selectAllEntries;
            } else {
                newState.bulkActions.selectAllEntries = false;
            }

            return {
                ...state,
                [name]: newState
            };
        }
        case actions.TOGGLE_BULK_ACTIONS_MODAL: {
            const newState = Object.assign({}, state[name]);
            return {
                ...state,
                [name]: {
                    ...newState,
                    ...{ bulkActions: { ...newState.bulkActions, ...{ modalOpened: response } } }
                }
            };
        }
        default:
            return state;
    }
};
