import restClient from 'erpcore/api/restClient';

/**
 * setToken is filling api clients with token
 * @param  {String} token JWT Token that's been using for API
 */
export function setToken(token) {
    localStorage.setItem('token', token);
}

/**
 * getToken is to recieve token
 * @param  {String} token JWT Token that's been using for API
 */
export function getToken() {
    return localStorage.getItem('token');
}

//  clearToken clearing clients from token
export function clearToken() {
    localStorage.removeItem('token');
    restClient.defaults.headers.Authorization = ``;
}

/**
 * setHash is filling api clients with hash
 * @param  {String} hash Hash that's been using for API
 */
export function setHash(hash) {
    localStorage.setItem('hash', hash);
    restClient.defaults.headers['X-AUTH-TOKEN'] = `${hash}`;
}

//  clearToken clearing clients from token
export function clearHash() {
    localStorage.removeItem('hash');
    delete restClient.defaults.headers['X-AUTH-TOKEN'];
}
