export const actions = {
    SET_URL_QUERY_PARAMS: 'SET_URL_QUERY_PARAMS',
    SET_IS_STRESS_TEST: 'SET_IS_STRESS_TEST'
};

export const initialState = {
    urlQueryParams: '',
    stress_test: false
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.SET_URL_QUERY_PARAMS: {
            return {
                ...state,
                urlQueryParams: response
            };
        }
        case actions.SET_IS_STRESS_TEST: {
            return {
                ...state,
                stress_test: response
            };
        }
        default:
            return state;
    }
};
