import uniqid from 'uniqid';
import { DEFAULT_BUCKET_KEY, getBucketKeyFromType } from 'erpcore/utils/entityUtils';
import set from 'lodash/set';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import dto from 'erpcore/utils/dto';

export const actions = {
    FETCH_SINGLE_RESOURCE_START: 'FETCH_SINGLE_RESOURCE_START',
    FETCH_SINGLE_RESOURCE_SUCCESS: 'FETCH_SINGLE_RESOURCE_SUCCESS',
    FETCH_SINGLE_RESOURCE_FAILED: 'FETCH_SINGLE_RESOURCE_FAILED',
    STORE_RESOURCE: 'STORE_RESOURCE',
    CLEAR_RESOURCES: 'CLEAR_RESOURCES',

    UPDATE_SINGLE_RESOURCE_START: 'UPDATE_SINGLE_RESOURCE_START',
    UPDATE_SINGLE_RESOURCE_SUCCESS: 'UPDATE_SINGLE_RESOURCE_SUCCESS',
    UPDATE_SINGLE_RESOURCE_FAILED: 'UPDATE_SINGLE_RESOURCE_FAILED',

    DELETE_SINGLE_RESOURCE_START: 'DELETE_SINGLE_RESOURCE_START',
    DELETE_SINGLE_RESOURCE_SUCCESS: 'DELETE_SINGLE_RESOURCE_SUCCESS',
    DELETE_SINGLE_RESOURCE_FAILED: 'DELETE_SINGLE_RESOURCE_FAILED',

    CREATE_SINGLE_RESOURCE_START: 'CREATE_SINGLE_RESOURCE_START',
    CREATE_SINGLE_RESOURCE_SUCCESS: 'CREATE_SINGLE_RESOURCE_SUCCESS',
    CREATE_SINGLE_RESOURCE_FAILED: 'CREATE_SINGLE_RESOURCE_FAILED',

    SET_MERCURE_ERROR: 'SET_MERCURE_ERROR,',
    REMOVE_MERCURE_ERROR: 'REMOVE_MERCURE_ERROR,'
};

export const initialState = {
    fetching: [],
    teams: null,
    participants: null,
    challenges: null,
    completedChallenges: null,
    challengeTimers: null,
    helpRequests: null,
    eventContent: null,
    eventContentItems: null,
    [DEFAULT_BUCKET_KEY]: null,
    mercureNotifications: null,
    mercureErrors: []
};

export default (state = initialState, { type, response, id, ignoreIncluded }) => {
    switch (type) {
        case actions.FETCH_SINGLE_RESOURCE_START: {
            const newFetching = Array.from(state.fetching);
            newFetching.push(id);
            return {
                ...state,
                fetching: newFetching
            };
        }
        case actions.UPDATE_SINGLE_RESOURCE_START: {
            const newFetching = Array.from(state.fetching);
            newFetching.push(id);
            return {
                ...state,
                fetching: newFetching
            };
        }
        case actions.FETCH_SINGLE_RESOURCE_FAILED: {
            return {
                ...state,
                fetching: state.fetching.filter(item => item !== id) || []
            };
        }
        case actions.UPDATE_SINGLE_RESOURCE_FAILED: {
            return {
                ...state,
                fetching: state.fetching.filter(item => item !== id) || []
            };
        }
        case actions.FETCH_SINGLE_RESOURCE_SUCCESS: {
            return {
                ...state,
                fetching: state.fetching.filter(item => item !== id) || []
            };
        }
        case actions.UPDATE_SINGLE_RESOURCE_SUCCESS: {
            return {
                ...state,
                fetching: state.fetching.filter(item => item !== id) || []
            };
        }
        case actions.STORE_RESOURCE: {
            // resources are not overwritten, they are merged (including relationships)!!!

            const bucketsForReferenceClearing = [];

            const getNewBucketReferences = (buckets, forwardState) => {
                return buckets?.length
                    ? buckets.reduce((accumulator, currentValue) => {
                          accumulator[currentValue] = { ...forwardState?.[currentValue] };
                          return accumulator;
                      }, {})
                    : null;
            };

            const tempState = { ...state };
            const isArray = Array.isArray(response?.data);
            const bucket = getBucketKeyFromType(
                isArray ? response?.data?.[0]?.type : response?.data?.type
            );
            const bucketState = tempState?.[bucket];

            bucketsForReferenceClearing.push(bucket);

            // early return / mercure notification
            const isMercureNotification = !('data' in response);
            if (isMercureNotification) {
                // TODO: dispatch different action for mercure notification
                // mercure notification
                return {
                    ...state,
                    mercureNotification: [
                        ...(state?.mercureNotification || []),
                        { ...response, id: uniqid() }
                    ]
                };
            }

            // early return / delete resource
            if (response?.status === 'delete') {
                if (response?.data?.id) {
                    delete tempState?.[bucket][response?.data?.id];
                    return {
                        ...tempState,
                        ...getNewBucketReferences(bucketsForReferenceClearing, tempState)
                    };
                }
                return { ...state };
            }

            const mergeResource = (resource, resourceBucketKey = 'default') => {
                const resourceBucketState =
                    resourceBucketKey === 'default' ? bucketState : tempState?.[resourceBucketKey];

                const resourceDto = dto({ data: resource }, ignoreIncluded, true);

                return {
                    ...resourceBucketState?.[resource?.id],
                    ...resourceDto
                };
            };

            if (isArray) {
                // collection of resources
                response.data.forEach(resource => {
                    set(tempState, `${bucket}.${resource?.id}`, mergeResource(resource));
                });
            } else {
                // single resource (doesn't overwrite, does merge)
                set(tempState, `${bucket}.${response?.data?.id}`, mergeResource(response?.data));
            }

            // add includes into bucket
            if (!ignoreIncluded && response?.included?.length) {
                response.included.forEach(item => {
                    const currentBucket = getBucketKeyFromType(item?.type);
                    if (!bucketsForReferenceClearing.includes(currentBucket)) {
                        bucketsForReferenceClearing.push(currentBucket);
                    }
                    set(
                        tempState,
                        `${currentBucket}.${item?.id}`,
                        mergeResource(item, currentBucket)
                    );
                });
            }

            const newBucketReferences = getNewBucketReferences(
                bucketsForReferenceClearing,
                tempState
            );

            return {
                ...tempState,
                ...newBucketReferences
            };
        }
        case actions.SET_MERCURE_ERROR: {
            return {
                ...state,
                mercureErrors: uniq([...state?.mercureErrors, id])
            };
        }
        case actions.REMOVE_MERCURE_ERROR: {
            return {
                ...state,
                mercureErrors: without(state?.mercureErrors, id)
            };
        }
        case actions.CLEAR_RESOURCES: {
            window.otbFetchedLeaderboardTeams = false;
            window.storingChallengeTypesIsDone = false;
            return {
                ...initialState
            };
        }
        default:
            return state;
    }
};
