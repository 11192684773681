import { takeLatest, put, call, select } from 'redux-saga/effects';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { getActiveEvent } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { actions as eventActions } from './Event.reducer';

/**
 * Starts an event
 * @param {Object} promise Promises
 * @return {Object} Response from API
 */
export function* startEvent({ promise }) {
    try {
        const activeEvent = yield select(getActiveEvent);
        yield restClient.put(`${activeEvent?.id}/start`, {
            headers: { 'Content-type': 'application/json' }
        });
        yield put({
            type: eventActions.START_EVENT_SUCCESSFUL
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventActions.START_EVENT_FAILED
        });
        const errorData = error?.response?.data || error;
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: errorData
        });
        yield call(promise.reject, errorData);
    }
}

/**
 * End an event
 * @param {Object} promise Promises
 * @return {Object} Response from API
 */
export function* endEvent({ promise }) {
    try {
        const activeEvent = yield select(getActiveEvent);
        yield restClient.put(`${activeEvent?.id}/end`, {
            headers: {
                'Content-type': 'application/json'
            }
        });
        yield put({
            type: eventActions.END_EVENT_SUCCESSFUL
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventActions.END_EVENT_FAILED
        });
        const errorData = error?.response?.data || error;
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: errorData
        });
        yield call(promise.reject, errorData);
    }
}

/**
 * Completes an event
 * @param {Object} promise Promises
 * @return {Object} Response from API
 */
export function* completeEvent({ promise }) {
    try {
        const activeEvent = yield select(getActiveEvent);
        yield restClient.put(`${activeEvent?.id}`, {
            status: 'completed'
        });
        yield put({
            type: eventActions.COMPLETE_EVENT_SUCCESSFUL
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventActions.COMPLETE_EVENT_FAILED
        });
        const errorData = error?.response?.data || error;
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: errorData
        });
        yield call(promise.reject, errorData);
    }
}

/**
 * End an event
 * @param {Object} promise Promises
 * @return {Object} Response from API
 */
export function* pauseEvent({ promise, pauseState: paused }) {
    try {
        const activeEvent = yield select(getActiveEvent);
        yield restClient.put(`${activeEvent?.id}`, {
            paused
        });
        yield put({
            type: eventActions.PAUSE_EVENT_SUCCESSFUL
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventActions.PAUSE_EVENT_FAILED
        });
        const errorData = error?.response?.data || error;
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: errorData
        });
        yield call(promise.reject, errorData);
    }
}

/**
 * Create new team
 * @param {Object} promise Promises
 * @param {String} eventID - event iri
 * @param {Object} formData - Form data
 * @return {Object} Response from API
 */
export function* createNewTeam({ promise, eventID, formData }) {
    try {
        const createNewTeamAPI = yield restClient.post('/api/event-teams', {
            event: eventID,
            ...formData
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createNewTeamAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Reshuffle teams
 * @param {Object} promise Promises
 * @param {String} eventID - event iri
 * @param {Object} formData - Form data
 * @return {Object} Response from API
 */
export function* reshuffleEventTeam({ promise, eventID, formData }) {
    try {
        const reshuffleEventTeamAPI = yield restClient.post(`${eventID}/reshuffle-teams`, formData);
        yield put({
            type: eventActions.RESHUFFLE_TEAMS_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: reshuffleEventTeamAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventActions.RESHUFFLE_TEAMS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Starts event challenge
 * @param  {Object} promise
 * @param {string} event Iri
 * @return {Object} response from API
 */
export function* startEventChallenge({ promise, iri }) {
    try {
        yield restClient.post(`${iri}/start`);

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Event Team Completed challenges
 * @param  {Object} promise
 * @param {string} teamID Iri
 * @return {Object} response from API
 */
export function* fetchTeamCompletedChallenges({ promise, teamID }) {
    try {
        if (teamID) {
            const fetchTeamCompletedChallengesAPI = yield restClient.get(
                `/api/completed-challenges`,
                {
                    params: { pagination: false, 'filters[eventTeam][equals]': teamID }
                }
            );
            yield put({
                type: eventActions.FETCHING_SUCCESSFUL_TEAM_COMPLETED_CHALLENGES,
                response: dto(fetchTeamCompletedChallengesAPI?.data)
            });
        }
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventActions.FETCHING_FAILED_TEAM_COMPLETED_CHALLENGES
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const eventSaga = [
    takeLatest(eventActions.START_EVENT, startEvent),
    takeLatest(eventActions.END_EVENT, endEvent),
    takeLatest(eventActions.PAUSE_EVENT, pauseEvent),
    takeLatest(eventActions.COMPLETE_EVENT, completeEvent),
    takeLatest(eventActions.CREATE_NEW_TEAM, createNewTeam),
    takeLatest(eventActions.START_RESHUFFLE_TEAMS, reshuffleEventTeam),
    takeLatest(eventActions.START_FETCHING_TEAM_COMPLETED_CHALLENGES, fetchTeamCompletedChallenges),
    takeLatest(eventActions.START_EVENT_CHALLENGE, startEventChallenge)
];
