import axios from 'axios';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getActiveEvent, getActiveUser } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import {
    isUserAdmin,
    getIsErpUserDataRetrieved
} from 'erpcore/utils/ErpUserManager/ErpUserManager.selectors';
import useIsJoinScreen from 'erpcore/utils/useIsJoinScreen';
import { getResourceByID } from 'erpcore/utils/ResourceManager/ResourceManager.selectors';

const ActiveUserHeartbeat = () => {
    const history = useHistory();
    const location = useLocation();
    const activeUser = useSelector(getActiveUser);
    const isJoinScreenActive = useIsJoinScreen();
    const isAdmin = useSelector(isUserAdmin);
    const isErpUserDataRetrieved = useSelector(getIsErpUserDataRetrieved);
    const isHeartBeatSetRef = useRef(false);
    const activeEvent = useSelector(getActiveEvent);
    const eventResource = useSelector(state => getResourceByID(state, activeEvent?.id));
    const cleanRestClient = axios.create({
        baseURL: process.env.REACT_APP_REST_API,
        timeout: process.env.REACT_APP_API_TIMEOUT,
        transformRequest: (data, headers) => {
            //  removing unnecessary jwt token
            delete headers.Authorization;
        }
    });

    useEffect(() => {
        let heartBeatInterval = null;
        if (
            eventResource?.id &&
            !eventResource?.single_participant_team &&
            !isHeartBeatSetRef?.current &&
            activeUser &&
            activeUser?.hash &&
            isErpUserDataRetrieved &&
            !isAdmin &&
            !isJoinScreenActive
        ) {
            isHeartBeatSetRef.current = true;
            heartBeatInterval = setInterval(() => {
                cleanRestClient
                    .put('/api/event-participant/me/heartbeat', null, {
                        headers: {
                            'X-AUTH-TOKEN': activeUser?.hash
                        }
                    })
                    .catch(error => {
                        // sign out if user not found
                        if (
                            error?.response?.data?.code === 'participant.notFound' &&
                            location.pathname !== '/kick-out'
                        ) {
                            history.push('/sign-out');
                        }
                    });
            }, 10000);
        }
        return () => {
            clearInterval(heartBeatInterval);
            isHeartBeatSetRef.current = false;
        };
    }, [
        isAdmin,
        isErpUserDataRetrieved,
        activeUser,
        isJoinScreenActive,
        history,
        location,
        eventResource?.id,
        eventResource?.single_participant_team
    ]);

    return null;
};

export default ActiveUserHeartbeat;
