import { takeLatest, takeEvery, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import axios from 'axios';
import { actions as resourceActions } from './ResourceManager.reducer';

export const defaultResourceParams = {
    include: [
        'coordinators,coordinators.image,coordinators.image.versions',
        'teams',
        'timezone',
        'participants,participants.image,participants.image.versions',
        'company',
        'company.logo',
        'image,image.versions,contents,contents.items,contents.items.media,contents.items.media.media',
        'eventChallenges',
        'eventChallenges.timers', // yes, don't remove
        'eventChallenges.challenge', // yes, don't remove
        'completedChallenges',
        'helpRequests',
        'managers',
        'admins'
    ].join(',')
};

export function* fetchSingleResource({
    promise,
    id,
    params = null,
    headers = {},
    ignoreIncluded,
    cleanRequest
}) {
    try {
        //  Rest client without x-auth-token
        const cleanRestClient = axios.create({
            baseURL: process.env.REACT_APP_REST_API,
            timeout: process.env.REACT_APP_API_TIMEOUT,
            transformRequest: (data, requestHeaders) => {
                delete requestHeaders['X-AUTH-TOKEN'];
                delete requestHeaders.Authorization;
            }
        });

        let fetchResourceDataAPI = null;

        if (cleanRequest) {
            fetchResourceDataAPI = yield cleanRestClient.get(id, {
                params: { ...(params || defaultResourceParams) },
                headers: {
                    ...headers
                }
            });
        } else {
            fetchResourceDataAPI = yield restClient.get(id, {
                params: { ...(params || defaultResourceParams) },
                headers: {
                    ...headers
                }
            });
        }

        const resourceData = fetchResourceDataAPI?.data;
        yield put({
            type: resourceActions.FETCH_SINGLE_RESOURCE_SUCCESS,
            id
        });
        yield put({
            type: resourceActions.STORE_RESOURCE,
            response: resourceData,
            ignoreIncluded,
            id
        });
        if (promise) yield call(promise.resolve, resourceData);
        return resourceData;
    } catch (error) {
        yield put({
            type: resourceActions.FETCH_SINGLE_RESOURCE_FAILED,
            id
        });
        const errorData = error?.response?.data || error;
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: errorData
        });
        if (promise) yield call(promise.reject, errorData);
        return errorData;
    }
}

export function* updateSingleResource({ promise, formData }) {
    try {
        const updateResourceDataAPI = yield restClient.put(formData?.id, formData);
        const resourceData = updateResourceDataAPI?.data;
        yield put({
            type: resourceActions.UPDATE_SINGLE_RESOURCE_SUCCESS,
            response: resourceData,
            id: formData?.id
        });
        if (promise) yield call(promise.resolve, resourceData);
        return resourceData;
    } catch (error) {
        yield put({
            type: resourceActions.UPDATE_SINGLE_RESOURCE_FAILED
        });
        const errorData = error?.response?.data || error;
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: errorData
        });
        if (promise) yield call(promise.reject, errorData);
        return errorData;
    }
}

export function* removeSingleResource({ promise, id, isFloatingNotification = false }) {
    try {
        const deleteResourceID = yield restClient.delete(id);
        const resourceData = deleteResourceID?.data;
        yield put({
            type: resourceActions.DELETE_SINGLE_RESOURCE_SUCCESS,
            response: resourceData
        });
        if (promise) yield call(promise.resolve, resourceData);
        return resourceData;
    } catch (error) {
        yield put({
            type: resourceActions.DELETE_SINGLE_RESOURCE_FAILED
        });
        const errorData = error?.response?.data || error;
        yield put({
            type: isFloatingNotification
                ? notificationManagerActions.ADD_FLOATING_NOTIFICATION
                : notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: errorData
        });
        if (promise) yield call(promise.reject, errorData);
        return errorData;
    }
}

export function* createSingleResource({
    promise,
    formData,
    resourceEndpoint,
    returnUnprocessedResponse = false
}) {
    try {
        const updateResourceDataAPI = yield restClient.post(resourceEndpoint, formData);
        const resourceData = updateResourceDataAPI?.data;
        yield put({
            type: resourceActions.CREATE_SINGLE_RESOURCE_SUCCESS,
            response: resourceData
        });
        if (promise)
            yield call(
                promise.resolve,
                returnUnprocessedResponse ? resourceData : dto(resourceData)
            );
        return resourceData;
    } catch (error) {
        yield put({
            type: resourceActions.CREATE_SINGLE_RESOURCE_FAILED
        });
        const errorData = error?.response?.data || error;
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: errorData
        });
        if (promise) yield call(promise.reject, errorData);
        return errorData;
    }
}

/**
 * Register action to watcher
 */
export const resourceManagerSaga = [
    takeEvery(resourceActions.FETCH_SINGLE_RESOURCE_START, fetchSingleResource),
    takeLatest(resourceActions.UPDATE_SINGLE_RESOURCE_START, updateSingleResource),
    takeLatest(resourceActions.DELETE_SINGLE_RESOURCE_START, removeSingleResource),
    takeLatest(resourceActions.CREATE_SINGLE_RESOURCE_START, createSingleResource)
];
