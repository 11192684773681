export const getErpUserData = state => state.erpUserManager?.data || null;

export const isUserAdmin = state => !!state.erpUserManager?.isErpUser || false;

export const isUserCustomerCoordinator = state =>
    !!state?.erpUserManager?.data?.userTypes?.find(
        item => item?.attribute === 'CAN_USER_TYPE_CUSTOMER_COORDINATOR'
    );

export const getIsErpUserDataRetrieved = state =>
    !!state.erpUserManager?.isErpUserDataRetrieved || false; // doesn't mean the user is erp admin, it just means that the data is retrieved
