import React, { useEffect, useRef, lazy, Suspense, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Color from 'color';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

import ErpUserManager from 'erpcore/utils/ErpUserManager';
import AppHeight from 'erpcore/components/AppHeight';
import MercureNotifications from 'erpcore/utils/MercureNotifications';
import ServerTimeManager from 'erpcore/utils/ServerTimeManager/ServerTimeManager';
import ActiveUserHeartbeat from 'erpcore/utils/AuthManager/ActiveUserHeartbeat';
import ParticipantAndActiveTeamSync from 'erpcore/utils/ParticipantAndActiveTeamSync';
import ManageMercureEventSourceSubscriptions from 'erpcore/utils/ResourceManager/ManageMercureEventSourceSubscriptions.jsx';
import MercureReconnectionModal from 'erpcore/components/MercureReconnectionModal';
import NotificationsLogModal from 'erpcore/components/NotificationsLogModal';
import PageLoader from 'erpcore/components/PageLoader';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as routerManagerActions } from 'erpcore/utils/RouterManager/RouterManager.reducer';
import { getActiveEvent, getSignedIn } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { getIsErpUserDataRetrieved } from 'erpcore/utils/ErpUserManager/ErpUserManager.selectors';
import { videoScriptsInit } from 'erpcore/components/VideoModule/VideoModule.utils';
import { Helmet } from 'react-helmet';
import { getEventData } from 'erpcore/screens/Join/Join.selectors';
import { actions as resourceActions } from 'erpcore/utils/ResourceManager/ResourceManager.reducer';
import { getResourceByID } from 'erpcore/utils/ResourceManager/ResourceManager.selectors';
import TeamLeaderChangedModal from 'erpcore/components/TeamLeaderChangedModal';
import { isStressTestRequested } from 'erpcore/utils/RouterManager/RouterManager.selectors';
import ManageCoordinatorChanges from 'erpcore/utils/ManageCoordinatorChanges';

// Auth
const Join = lazy(() => import('erpcore/screens/Join'));
const JoinDirect = lazy(() => import('erpcore/screens/Join/JoinDirect.jsx'));
const Lobby = lazy(() => import('erpcore/screens/Lobby'));
const NotFound = lazy(() => import('erpcore/screens/NotFound'));
const GeneralError = lazy(() => import('erpcore/screens/GeneralError'));
const SignOut = lazy(() => import('erpcore/screens/SignOut'));
const KickOut = lazy(() => import('erpcore/screens/KickOut'));
const LobbyAdmin = lazy(() => import('erpcore/screens/LobbyAdmin'));
const Event = lazy(() => import('erpcore/screens/Event'));

/**
 * Router Manager
 * @return {Node} Returns current active route component
 */
const RouterManager = ({ location }) => {
    const dispatch = useDispatch();
    const isSignedIn = useSelector(getSignedIn);
    const activeEvent = useSelector(getActiveEvent);
    const hashEventData = useSelector(getEventData);
    const eventResource = useSelector(state => getResourceByID(state, activeEvent?.id));
    const allowedToClearNotifications = useRef(false);
    const isErpUserDataRetrieved = useSelector(getIsErpUserDataRetrieved);
    const isStressTesting = useSelector(isStressTestRequested);
    const { pathname, search } = location;

    const [videoScriptsInitialized, setVideoScriptsInitialized] = useState(false);

    const clearNotificationManagerPageNotification = () => {
        dispatch({
            type: notificationManagerActions.REMOVE_PAGE_NOTIFICATIONS,
            initiator: 'router'
        });
    };

    const setUrlQueryParams = params => {
        dispatch({
            type: routerManagerActions.SET_URL_QUERY_PARAMS,
            response: params
        });
    };

    useEffect(() => {
        if (isErpUserDataRetrieved && !videoScriptsInitialized) {
            setVideoScriptsInitialized(true);
            videoScriptsInit();
        }
    }, [isErpUserDataRetrieved, videoScriptsInitialized]);

    const handleLocationChange = () => {
        if (allowedToClearNotifications.current) {
            // Clear Page Notifications
            clearNotificationManagerPageNotification();
        }
        allowedToClearNotifications.current = true;
        setUrlQueryParams('');
    };

    const renderBodyClassName = () => {
        //  dynamically updating body className
        let cssClass = 'page-root';
        if (pathname && pathname !== '/') {
            cssClass = location.pathname.replace(/\//, 'pageT-');
            cssClass = cssClass.replace(/\//g, '-');
        }

        document.body.className = cssClass;
    };

    const activeEventData = hashEventData?.settings || eventResource?.settings || {};
    const { customerLogo, customerLogoInverted, whitelabelLogo } = activeEventData;
    const fetchWLResources = useCallback(
        id =>
            new Promise((resolve, reject) => {
                dispatch({
                    type: resourceActions.FETCH_SINGLE_RESOURCE_START,
                    promise: { resolve, reject },
                    id,
                    params: {}
                });
            }),
        [customerLogo]
    );
    useEffect(() => {
        if (customerLogo) {
            fetchWLResources(customerLogo);
        }
    }, [customerLogo]);
    useEffect(() => {
        if (customerLogoInverted) {
            fetchWLResources(customerLogoInverted);
        }
    }, [customerLogoInverted]);
    useEffect(() => {
        if (whitelabelLogo) {
            fetchWLResources(whitelabelLogo);
        }
    }, [whitelabelLogo]);

    const handleWLCssVars = () => {
        const { primaryColor } = activeEventData;
        if (primaryColor) {
            return (
                <Helmet>
                    <style type="text/css">
                        {`
                        :root {
                            ${primaryColor ? `--primaryColor: ${primaryColor};` : ''}
                            ${
                                primaryColor
                                    ? `--primaryHover: ${Color(primaryColor)
                                          .darken(0.2)
                                          .hex()};`
                                    : ''
                            }
                        }
                    `}
                    </style>
                </Helmet>
            );
        }
        return null;
    };

    /**
     * Effect used only on location path (route) change
     */
    useEffect(() => {
        // Invoke functions
        renderBodyClassName();
        handleLocationChange();
    }, [pathname]);

    /**
     * Effect used only on location search (query params) change
     */
    useEffect(() => {
        setUrlQueryParams(search);
    }, [search]);

    // Render the public router when the user is not signed in
    return (
        <>
            {!isErpUserDataRetrieved && (
                <>
                    <Suspense fallback={<PageLoader />}>
                        <Switch>
                            <Route
                                key="JoinDirect"
                                path="/join-direct/:eventHash/:participantHash"
                                exact
                                component={JoinDirect}
                            />
                            <Route key="Error" path="/error" exact component={GeneralError} />
                            <Route
                                key="Initial"
                                path={[
                                    '/join/:eventHash',
                                    '/join/:eventHash/:participantHash',
                                    '/coordinator-join/:eventHash/:userHash',
                                    '/admin',
                                    '/event',
                                    '/',
                                    '/not-found',
                                    '/kick-out'
                                ]}
                                exact
                                component={ErpUserManager}
                            />

                            <Route key="SignOut" path="/sign-out" exact component={SignOut} />
                        </Switch>
                    </Suspense>
                </>
            )}
            {!!isErpUserDataRetrieved && !activeEvent && (
                <Suspense fallback={<PageLoader />}>
                    <Switch>
                        <Route
                            key="NotFound"
                            path={['/', '/not-found']}
                            exact
                            component={NotFound}
                        />
                        <Route key="Error" path="/error" exact component={GeneralError} />
                        <Route
                            key="JoinDirect"
                            path="/join-direct/:eventHash/:participantHash"
                            exact
                            component={JoinDirect}
                        />
                        <Route
                            key="JoinScreen"
                            path={['/join/:eventHash', '/join/:eventHash/:participantHash']}
                            exact
                            component={Join}
                        />
                        <Redirect
                            to={{
                                pathname: '/',
                                state: { from: location }
                            }}
                        />
                    </Switch>
                </Suspense>
            )}
            {!!isErpUserDataRetrieved && !!activeEvent && (
                <>
                    <ServerTimeManager />
                    {isSignedIn ? (
                        <Suspense fallback={<PageLoader />}>
                            <Switch>
                                <Route
                                    key="JoinDirect"
                                    path="/join-direct/:eventHash/:participantHash"
                                    exact
                                    component={JoinDirect}
                                />
                                <Route
                                    key="JoinScreen"
                                    path={['/join/:eventHash', '/join/:eventHash/:participantHash']}
                                    exact
                                    component={Join}
                                />
                                <Route
                                    key="LobbyAdmin"
                                    path="/admin"
                                    exact
                                    component={LobbyAdmin}
                                />
                                <Route key="LobbyScreen" path="/" exact component={Lobby} />
                                <Route key="EventScreen" path="/event" exact component={Event} />
                                <Route key="SignOut" path="/sign-out" exact component={SignOut} />
                                <Route key="KickOut" path="/kick-out" exact component={KickOut} />
                                <Redirect
                                    to={{
                                        pathname: '/',
                                        state: {
                                            from: location
                                        }
                                    }}
                                />
                            </Switch>
                        </Suspense>
                    ) : (
                        <Suspense fallback={<PageLoader />}>
                            <Switch>
                                <Route
                                    key="NotFound"
                                    path={['/', '/not-found']}
                                    exact
                                    component={NotFound}
                                />
                                <Route key="Error" path="/error" exact component={GeneralError} />
                                <Route
                                    key="JoinDirect"
                                    path="/join-direct/:eventHash/:participantHash"
                                    exact
                                    component={JoinDirect}
                                />
                                <Route
                                    key="JoinScreen"
                                    path={[
                                        '/join/:eventHash',
                                        '/join/:eventHash/:participantHash',
                                        '/coordinator-join/:eventHash/:userHash'
                                    ]}
                                    exact
                                    component={Join}
                                />
                                <Redirect
                                    to={{
                                        pathname: '/',
                                        state: {
                                            from: location
                                        }
                                    }}
                                />
                            </Switch>
                        </Suspense>
                    )}
                </>
            )}

            <AppHeight />
            <ManageMercureEventSourceSubscriptions />
            <ManageCoordinatorChanges />
            <MercureReconnectionModal />
            <ActiveUserHeartbeat />
            <ParticipantAndActiveTeamSync />
            <MercureNotifications />
            <NotificationsLogModal />
            {!isStressTesting && <TeamLeaderChangedModal />}
            {handleWLCssVars()}
        </>
    );
};

RouterManager.defaultProps = {
    location: {}
};

RouterManager.propTypes = {
    location: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(RouterManager);
