export const actions = {
    SET_USER_LOCAL_TRACKS_ERROR: 'SET_USER_LOCAL_TRACKS_ERROR',
    SET_USER_LOCAL_TRACKS: 'SET_USER_LOCAL_TRACKS',
    UPDATE_USER_LOCAL_TRACK: 'UPDATE_USER_LOCAL_TRACK',

    START_POSTING_CHAT_MESSAGE: 'START_POSTING_CHAT_MESSAGE',
    POSTING_CHAT_MESSAGE_SUCCESSFUL: 'POSTING_CHAT_MESSAGE_SUCCESSFUL',
    POSTING_CHAT_MESSAGE_FAILED: 'POSTING_CHAT_MESSAGE_FAILED',

    START_FETCHING_CHAT_MESSAGES: 'START_FETCHING_CHAT_MESSAGES',
    FETCHING_CHAT_MESSAGES_SUCCESSFUL: 'FETCHING_CHAT_MESSAGES_SUCCESSFUL',
    FETCHING_CHAT_MESSAGES_FAILED: 'FETCHING_CHAT_MESSAGES_FAILED',
    ADD_CHAT_MESSAGE: 'ADD_CHAT_MESSAGE',
    ADD_UNREAD_MESSAGE: 'ADD_UNREAD_MESSAGE',
    RESET_UNREAD_MESSAGES: 'RESET_UNREAD_MESSAGES',

    START_FETCHING_VIDEO_SCRIPTS: 'START_FETCHING_VIDEO_SCRIPTS',
    FETCHING_VIDEO_SCRIPTS_SUCCESSFUL: 'FETCHING_VIDEO_SCRIPTS_SUCCESSFUL',
    FETCHING_VIDEO_SCRIPTS_FAILED: 'FETCHING_VIDEO_SCRIPTS_FAILED',

    STORE_DEVICES: 'STORE_DEVICES',
    SET_JITSI_CONFIGURATION: 'SET_JITSI_CONFIGURATION',
    SET_GAME_ROOM_DATA: 'SET_GAME_ROOM_DATA',
    SET_CONNECTION_DATA: 'SET_CONNECTION_DATA',

    USER_JOINED: 'USER_JOINED',
    USER_LEFT: 'USER_LEFT',
    ADD_USER_TRACKS: 'ADD_USER_TRACKS',
    REMOVE_USER_TRACKS: 'REMOVE_USER_TRACKS',

    SET_DOMINANT_SPEAKER: 'SET_DOMINANT_SPEAKER',
    SET_INITIAL_PARTICIPANTS: 'SET_INITIAL_PARTICIPANTS',

    SET_CURRENT_ACTIVE_ROOM_HASH: 'SET_CURRENT_ACTIVE_ROOM_HASH',
    CLEAN_UP: 'CLEAN_UP'
};

export const initialState = {
    loading: undefined,
    localTracks: {},
    currentActiveRoomHash: null,
    localTracksError: false,
    devices: {},
    configuration: {},
    gameRoom: null,
    connection: null,
    remoteTracks: {
        participants: []
    },
    dominantSpeaker: {
        pinned: false,
        userID: null
    },
    chatMessages: [],
    unReadChatMessages: 0,
    fetchingChatMessages: false
};

export default (
    state = initialState,
    {
        type,
        tracks,
        devices,
        trackType,
        track,
        payload,
        userID,
        pinned,
        messages,
        newMessage,
        participants,
        connection,
        self,
        room
    }
) => {
    switch (type) {
        case actions.START_FETCHING_VIDEO_SCRIPTS: {
            return {
                ...state,
                loading: true
            };
        }
        case actions.FETCHING_VIDEO_SCRIPTS_SUCCESSFUL: {
            return {
                ...state,
                loading: false
            };
        }
        case actions.FETCHING_VIDEO_SCRIPTS_FAILED: {
            return {
                ...state,
                loading: false
            };
        }
        case actions.START_FETCHING_CHAT_MESSAGES: {
            return {
                ...state,
                fetchingChatMessages: true
            };
        }
        case actions.FETCHING_CHAT_MESSAGES_SUCCESSFUL: {
            return {
                ...state,
                fetchingChatMessages: false,
                ...(messages ? { chatMessages: messages } : null)
            };
        }
        case actions.FETCHING_CHAT_MESSAGES_FAILED: {
            return {
                ...state,
                fetchingChatMessages: false
            };
        }
        case actions.ADD_CHAT_MESSAGE: {
            return {
                ...state,
                chatMessages: [...state?.chatMessages, newMessage]
            };
        }
        case actions.SET_USER_LOCAL_TRACKS_ERROR:
            return {
                ...state,
                localTracksError: true
            };
        case actions.SET_USER_LOCAL_TRACKS:
            return {
                ...state,
                localTracks: tracks,
                localTracksError: false
            };
        case actions.UPDATE_USER_LOCAL_TRACK: {
            return {
                ...state,
                localTracks: {
                    ...state.localTracks,
                    [trackType]: track
                },
                localTracksError: false
            };
        }
        case actions.ADD_UNREAD_MESSAGE: {
            return {
                ...state,
                unReadChatMessages: state.unReadChatMessages + 1
            };
        }
        case actions.RESET_UNREAD_MESSAGES: {
            return {
                ...state,
                unReadChatMessages: 0
            };
        }
        case actions.STORE_DEVICES: {
            return {
                ...state,
                devices
            };
        }
        case actions.SET_JITSI_CONFIGURATION: {
            return {
                ...state,
                configuration: payload
            };
        }
        case actions.SET_GAME_ROOM_DATA: {
            return {
                ...state,
                gameRoom: payload
            };
        }
        case actions.USER_JOINED: {
            if (state?.remoteTracks?.participants?.includes(userID)) {
                return state;
            }

            return {
                ...state,
                remoteTracks: {
                    ...state.remoteTracks,
                    participants: [...state.remoteTracks.participants, userID],
                    [userID]: {}
                }
            };
        }
        case actions.SET_INITIAL_PARTICIPANTS: {
            const participantsAndTheirTracks = {};

            // For every participant in the conference, created an object with their ID as key and their tracks
            participants.forEach(participant => {
                if (participant?.getId()) {
                    // Set initial object
                    participantsAndTheirTracks[participant?.getId()] = {};

                    if (participant?._tracks) {
                        const { _tracks: participantTracks = [] } = { ...participant };
                        // Go trough all the tracks and assign them to his object ID key
                        participantTracks.forEach(singleParticipantTrack => {
                            participantsAndTheirTracks[participant?.getId()][
                                singleParticipantTrack?.getType()
                            ] = singleParticipantTrack;
                        });
                    } else {
                        participantsAndTheirTracks[participant?.getId()] = {};
                    }
                }
            });

            return {
                ...state,
                remoteTracks: {
                    ...state.remoteTracks,
                    participants: [
                        ...new Set([
                            ...state.remoteTracks.participants,
                            ...participants.map(participant => participant?.getId())
                        ])
                    ],
                    ...participantsAndTheirTracks
                }
            };
        }
        case actions.USER_LEFT: {
            const remoteTracksObject = state.remoteTracks;
            const trackIndex = remoteTracksObject.participants.findIndex(id => id === userID);

            if (trackIndex > -1) {
                const arr = Array.from(remoteTracksObject.participants);
                arr.splice(trackIndex, 1);

                remoteTracksObject.participants = arr;
            }

            if (remoteTracksObject?.[userID]) {
                delete remoteTracksObject?.[userID];
            }

            return {
                ...state,
                remoteTracks: remoteTracksObject
            };
        }
        case actions.ADD_USER_TRACKS: {
            if (state?.remoteTracks?.[userID]) {
                if (self) {
                    const localTracks = { ...state?.localTracks };

                    localTracks[trackType] = track;

                    return {
                        ...state,
                        remoteTracks: {
                            ...state.remoteTracks,
                            [userID]: {
                                ...state.remoteTracks?.[userID],
                                [trackType]: track
                            }
                        },
                        localTracks
                    };
                }

                return {
                    ...state,
                    remoteTracks: {
                        ...state.remoteTracks,
                        [userID]: {
                            ...state.remoteTracks?.[userID],
                            [trackType]: track
                        }
                    }
                };
            }
            return state;
        }

        case actions.REMOVE_USER_TRACKS: {
            const tracksObject = state?.remoteTracks?.[userID] || {};
            const localTracks = state?.localTracks || {};

            if (tracksObject?.[trackType]) {
                delete tracksObject[trackType];
            }

            if (self) {
                delete localTracks?.[trackType];
            }

            return {
                ...state,
                remoteTracks: {
                    ...state.remoteTracks,
                    [userID]: tracksObject
                },
                localTracks
            };
        }
        case actions.SET_DOMINANT_SPEAKER: {
            return {
                ...state,
                dominantSpeaker: {
                    pinned: pinned || false,
                    userID: userID || null
                }
            };
        }
        case actions.SET_CONNECTION_DATA: {
            return {
                ...state,
                connection
            };
        }
        case actions.SET_CURRENT_ACTIVE_ROOM_HASH: {
            return {
                ...state,
                currentActiveRoomHash: room
            };
        }

        case actions.CLEAN_UP: {
            try {
                state.connection.disconnect();
            } catch (e) {
                //
            }

            return {
                ...state,
                currentActiveRoomHash: null,
                gameRoom: null,
                connection: null,
                remoteTracks: {
                    participants: []
                },
                dominantSpeaker: {
                    pinned: false,
                    userID: null
                },
                chatMessages: [],
                unReadChatMessages: 0,
                fetchingChatMessages: false
            };
        }

        default:
            return state;
    }
};
