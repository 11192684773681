export default {
    REACT_APP_ENV: process.env.REACT_APP_ENV,
    NODE_ENV: process.env.NODE_ENV,
    REST_API: process.env.REACT_APP_REST_API || process.env.STORYBOOK_REST_API,
    REST_API_TIMEOUT: process.env.REACT_APP_API_TIMEOUT || process.env.STORYBOOK_API_TIMEOUT,
    GOOGLE_MAPS_API_KEY:
        process.env.REACT_APP_GOOGLE_MAPS_API_KEY || process.env.STORYBOOK_GOOGLE_MAPS_API_KEY,
    REDUX_DEBUGGER: process.env.REACT_APP_REDUX_DEBUGGER || process.env.STORYBOOK_REDUX_DEBUGGER,
    SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN || process.env.STORYBOOK_SENTRY_DSN,
    REACT_APP_NAME: process.env.REACT_APP_NAME,
    CI_COMMIT_SHORT_SHA: process.env.REACT_APP_CI_COMMIT_SHORT_SHA,
    CI_JOB_ID: process.env.REACT_APP_CI_JOB_ID,
    CI_JOB_URL: process.env.REACT_APP_CI_JOB_URL,
    MERCURE_ENDPOINT: process.env.REACT_APP_MERCURE_ENDPOINT,
    MERCURE_COOKIE_ENDPOINT: process.env.REACT_APP_MERCURE_COOKIE_ENDPOINT
};
