import { all } from 'redux-saga/effects';
import { authManagerSaga } from 'erpcore/utils/AuthManager/AuthManager.saga';
import { resourceManagerSaga } from 'erpcore/utils/ResourceManager/ResourceManager.saga';
import imageManagerSaga from 'erpcore/components/ImageManager/ImageManager.saga';
import { listingSaga } from 'erpcore/components/Listing/Listing.saga';
import { joinSaga } from 'erpcore/screens/Join/Join.saga';
import { eventSaga } from 'erpcore/screens/Event/Event.saga';
import { videoModuleSaga } from 'erpcore/components/VideoModule/VideoModule.saga';

export default function* rootSaga() {
    yield all([...authManagerSaga]);
    yield all([...imageManagerSaga]);
    yield all([...listingSaga]);
    yield all([...joinSaga]);
    yield all([...eventSaga]);
    yield all([...videoModuleSaga]);
    yield all([...resourceManagerSaga]);
}
