import { takeLatest, put, call } from 'redux-saga/effects';
import { clearToken, clearHash } from 'erpcore/api';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { actions as notificationManagerActions } from '../NotificationManager/NotificationManager.reducer';
import { actions as authActions } from './AuthManager.reducer';

/**
 * /me enpoint params
 */
const meParams = {
    include: 'image,image.versions,timezone,userTypes'
};

/**
 * Fetch User Data
 * @param  {Object} promise Promises
 * @return {Object} Response from API
 */
export function* fetchMe({ promise }) {
    // Get user data
    try {
        const getMe = yield restClient.get('/api/me', {
            params: meParams,
            transformRequest: (data, headers) => {
                delete headers['X-AUTH-TOKEN'];
                return data;
            }
        });
        yield put({
            type: authActions.FETCHING_ME_SUCCESSFULL
        });

        yield call(promise.resolve, dto(getMe?.data));
    } catch (error) {
        yield put({
            type: authActions.FETCHING_ME_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Sign Out Saga
 */
export function* signOut() {
    try {
        window.otbFetchedLeaderboardTeams = false;
        window.storingChallengeTypesIsDone = false;
        clearHash();
        clearToken();
        // This action is handled in main reducer file where it resets the redux store
        yield put({ type: authActions.SIGN_OUT_SUCCESSFUL });
    } catch (error) {
        yield console.error(error);
    }
}

/**
 * Register action to watcher
 */
export const authManagerSaga = [
    takeLatest(authActions.START_SIGN_OUT, signOut),
    takeLatest(authActions.START_FETCHING_ME, fetchMe)
];
