export const actions = {
    START_FETCHING_EVENT_DATA: 'START_FETCHING_EVENT_DATA',
    FETCHING_EVENT_DATA_SUCCESSFUL: 'FETCHING_EVENT_DATA_SUCCESSFUL',
    FETCHING_EVENT_DATA_FAILED: 'FETCHING_EVENT_DATA_FAILED',

    START_UPDATE_EVENT_PARTICIPANT: 'START_UPDATE_EVENT_PARTICIPANT',
    UPDATE_EVENT_PARTICIPANT_SUCCESSFUL: 'UPDATE_EVENT_PARTICIPANT_SUCCESSFUL',
    UPDATE_EVENT_PARTICIPANT_FAILED: 'UPDATE_EVENT_PARTICIPANT_FAILED',

    START_CREATE_EVENT_PARTICIPANT: 'START_CREATE_EVENT_PARTICIPANT',
    CREATE_EVENT_PARTICIPANT_SUCCESSFUL: 'CREATE_EVENT_PARTICIPANT_SUCCESSFUL',
    CREATE_EVENT_PARTICIPANT_FAILED: 'CREATE_EVENT_PARTICIPANT_FAILED',

    START_DIRECT_JOIN_EVENT_PARTICIPANT: 'START_DIRECT_JOIN_EVENT_PARTICIPANT',
    START_JOIN_EVENT_PARTICIPANT: 'START_JOIN_EVENT_PARTICIPANT',
    JOIN_EVENT_PARTICIPANT_SUCCESSFUL: 'JOIN_EVENT_PARTICIPANT_SUCCESSFUL',
    JOIN_EVENT_PARTICIPANT_FAILED: 'JOIN_EVENT_PARTICIPANT_FAILED',

    START_EVENT: 'START_EVENT',
    START_EVENT_SUCCESSFUL: 'START_EVENT_SUCCESSUL',
    START_EVENT_FAILED: 'START_EVENT_FAILED'
};

export const initialState = {
    fetchingEventData: false,
    eventData: null,
    eventStarting: false
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_EVENT: {
            return {
                ...state,
                eventStarting: true
            };
        }
        case actions.START_EVENT_SUCCESSFUL: {
            return {
                ...state,
                eventStarting: false
            };
        }
        case actions.START_EVENT_FAILED: {
            return {
                ...state,
                eventStarting: false
            };
        }
        case actions.START_FETCHING_EVENT_DATA: {
            return {
                ...state,
                fetchingEventData: true
            };
        }
        case actions.FETCHING_EVENT_DATA_SUCCESSFUL:
            return {
                ...state,
                fetchingEventData: false,
                eventData: response
            };
        case actions.FETCHING_EVENT_DATA_FAILED: {
            return {
                ...state,
                fetchingEventData: false
            };
        }
        case actions.JOIN_EVENT_PARTICIPANT_SUCCESSFUL: {
            return {
                ...state,
                eventData: null
            };
        }
        default:
            return state;
    }
};
