import React from 'react';
import enviromentVariables from 'erpcore/utils/enviromentVariables';
import styles from './AppVersion.module.scss';

const AppVersion = () => {
    return (
        <>
            <p className={styles.appVersion}>
                version: cluster v1 {enviromentVariables?.CI_COMMIT_SHORT_SHA || ''}
            </p>
            <span className={styles.appVersionVisual} />
        </>
    );
};

export default AppVersion;
