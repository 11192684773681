import { getBucketKeyFromIri } from 'erpcore/utils/entityUtils';

export const getResourceByID = (state, iri) =>
    state?.resourceManager?.[getBucketKeyFromIri(iri)]?.[iri] || null;

export const getResourcesFetching = state => state?.resourceManager?.fetching;

export const getRestOfDataRaw = state => state?.resourceManager?.rest || {};

export const getMercureNotifications = state => state?.resourceManager?.mercureNotification;

export const getMercureErrors = state => state?.resourceManager?.mercureErrors;
