import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Default System Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const VideoModuleNotifications = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'videoModule.permissionsDenied':
            return (
                <Notification
                    identifier={identifier}
                    title="Info"
                    text="Permissions denied for devices: audio/video. Please make sure they are set to allowed and refresh the page."
                    type="error"
                />
            );
        case 'videoModule.unableToAccessCamera':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="Unable to access camera, make sure camera permissison is set to allowed and refresh to page."
                    type="error"
                    expire="3500"
                />
            );
        case 'videoModule.cameraIsMuted':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="Please make sure your camera is not muted ( camera icon under the box ) and wait for the video to show up"
                    type="error"
                    expire="3500"
                />
            );
        case 'videoModule.general':
            return (
                <Notification identifier={identifier} title="Error" text={detail} type="error" />
            );
        case 'videoModule.errorLoadingCamera':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="Could not access camera. Camera might already be in use elsewhere, make sure camera is only opened in this app (this might require refreshing the page)."
                    type="error"
                />
            );
        case 'videoModule.cameraAlreadyUsed':
            return (
                <Notification
                    identifier={identifier}
                    title="Info"
                    text="Cannot unmute camera because one of the challenges that are using camera is still active"
                    type="info"
                />
            );
        case 'videoModule.errorSwitchingCamera':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="Unexpected error while switching camera"
                    type="error"
                />
            );
        default:
            return null;
    }
};

VideoModuleNotifications.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

VideoModuleNotifications.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default VideoModuleNotifications;
