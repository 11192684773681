import React, { useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ModalPlugin from 'react-modal';
import Svg from 'erpcore/components/Svg';
import { getModal } from 'erpcore/utils/LayoutManager/LayoutManager.selectors';
import { actions as layoutActions } from 'erpcore/utils/LayoutManager/LayoutManager.reducer';
import './Modal.scss';

const cnOpen = 'ReactModal__Body--open';
const cnTransparentOverlayDesktop = 'ReactModal__Body--transparent-overlay-desktop';
const cnTransparentOverlayMobilep = 'ReactModal__Body--transparent-overlay-mobile';
const cnBlurDesktop = 'ReactModal__Body--blur-desktop';
const cnBlurMobile = 'ReactModal__Body--blur-mobile';

const Modal = ({
    className,
    opened,
    onClose,
    title,
    subtitle,
    children,
    showClose,
    transparentOverlayDesktop,
    transparentOverlayMobile,
    blurDesktop,
    blurMobile,
    id
}) => {
    const dispatch = useDispatch();
    const storeOpened = useSelector(state => getModal(state, id));

    const isOpen = opened || storeOpened;

    const isOpenRef = useRef(isOpen);

    const additionalSafeBodyClassNameCleanup = useCallback(() => {
        // Because very rarely the Modal will not clean this up itself.
        document.body.classList.remove(cnOpen);
        document.body.classList.remove(cnTransparentOverlayDesktop);
        document.body.classList.remove(cnTransparentOverlayMobilep);
        document.body.classList.remove(cnBlurDesktop);
        document.body.classList.remove(cnBlurMobile);
    }, []);

    useEffect(() => {
        if (!isOpen && !!isOpenRef?.current) {
            additionalSafeBodyClassNameCleanup();
        }
        isOpenRef.current = isOpen;
    }, [isOpen]);

    useEffect(() => {
        return () => additionalSafeBodyClassNameCleanup();
    }, []);

    const onCloseDefault = () => dispatch({ type: layoutActions.CLOSE_MODAL, id });

    const bodyOpenClassName = [
        cnOpen,
        ...(transparentOverlayDesktop ? [cnTransparentOverlayDesktop] : []),
        ...(transparentOverlayMobile ? [cnTransparentOverlayMobilep] : []),
        ...(blurDesktop ? [cnBlurDesktop] : []),
        ...(blurMobile ? [cnBlurMobile] : [])
    ].join(' ');

    const parentSelector = useCallback(() => {
        return document.getElementById('modals-wrapper') || document.body;
    }, []);

    return (
        <ModalPlugin
            parentSelector={parentSelector}
            // {...(root ? { parentSelector: () => document.querySelector(root) } : null)}
            ariaHideApp={false}
            onRequestClose={onClose || onCloseDefault}
            className={`modal ${className}`}
            isOpen={isOpen}
            bodyOpenClassName={bodyOpenClassName}
        >
            {(title || subtitle) && (
                <div className="modal__header">
                    {title && <h3 className="modal__title">{title}</h3>}
                    {subtitle && <h4 className="modal__subtitle">{subtitle}</h4>}
                </div>
            )}
            <div className="modal__content">{children}</div>
            {!!showClose && (
                <button type="button" className="modal__close" onClick={onClose || onCloseDefault}>
                    <Svg icon="close" />
                </button>
            )}
        </ModalPlugin>
    );
};

Modal.defaultProps = {
    className: '',
    title: null,
    subtitle: null,
    children: null,
    opened: false,
    onClose: null,
    showClose: true,
    transparentOverlayDesktop: false,
    transparentOverlayMobile: false,
    blurDesktop: true,
    blurMobile: true,
    id: null
};

Modal.propTypes = {
    className: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.string
    ]),
    opened: PropTypes.bool,
    onClose: PropTypes.func,
    showClose: PropTypes.bool,
    transparentOverlayDesktop: PropTypes.bool,
    transparentOverlayMobile: PropTypes.bool,
    blurDesktop: PropTypes.bool,
    blurMobile: PropTypes.bool,
    id: PropTypes.string
};

export default Modal;
