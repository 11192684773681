export const actions = {
    STORE_ERP_USER_DATA: 'STORE_ERP_USER_DATA',
    DELETE_ERP_USER_DATA: 'DELETE_ERP_USER_DATA'
};

export const initialState = {
    isErpUserDataRetrieved: false,
    isErpUser: false,
    data: null
};

const noUser = {
    isErpUserDataRetrieved: true,
    isErpUser: false,
    data: null
};

export default (state = initialState, { type, data = null }) => {
    switch (type) {
        case actions.STORE_ERP_USER_DATA: {
            if (data) {
                const newData = { ...data };
                if (newData.image?.created_by) {
                    delete newData.image.created_by;
                }
                return {
                    ...state,
                    isErpUserDataRetrieved: true,
                    isErpUser: true,
                    data: newData
                };
            }
            return {
                ...state,
                ...noUser
            };
        }
        case actions.DELETE_ERP_USER_DATA: {
            return {
                ...state,
                ...noUser
            };
        }
        default:
            return state;
    }
};
