export const actions = {
    STARTED_FETCHING_SERVER_TIME: 'STARTED_FETCHING_SERVER_TIME',
    SET_SERVER_TIME_DIFFERENCE: 'SET_SERVER_TIME_DIFFERENCE'
};

export const initialState = {
    isServerTimeFetched: false,
    serverTimeFetchCount: 0,
    serverTimeFetchedAt: false,
    serverDiffFromLocal: null
};

export default (state = initialState, { type, serverDiffFromLocal, serverTimeFetchedAt }) => {
    switch (type) {
        case actions.SET_SERVER_TIME_DIFFERENCE: {
            return {
                ...state,
                isServerTimeFetched: true,
                serverTimeFetchCount: (state?.serverTimeFetchCount || 0) + 1,
                serverTimeFetchedAt: serverTimeFetchedAt || +new Date(), // timestamp
                serverDiffFromLocal
            };
        }
        default:
            return state;
    }
};
