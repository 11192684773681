import React, { useEffect, useState, useCallback } from 'react';
import Modal from 'erpcore/components/Modal';
import teamLeaderChangesImage from 'erpcore/assets/svg/teamLeaderChanges.svg';
import Button from 'erpcore/components/Button';
import { useSelector } from 'react-redux';
import { getActiveUser } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { getResourceByID } from 'erpcore/utils/ResourceManager/ResourceManager.selectors';
import { useLocation } from 'react-router-dom';
import style from './TeamLeaderChangedModal.module.scss';

// TODO: antonio: check if userData?.team_leader works
const TeamLeaderChangedModal = () => {
    const location = useLocation();
    const activeUser = useSelector(getActiveUser);
    const userData = useSelector(state => getResourceByID(state, activeUser?.id));
    const teamData = useSelector(state => getResourceByID(state, userData?.event_team?.id));
    const eventResource = useSelector(state => getResourceByID(state, userData?.event?.id));
    const iAmTeamLeader = !!userData?.team_leader; // this does NOT have to be memoized
    const [openedModal, setOpenedModal] = useState(false);
    const handleModal = useCallback(() => {
        setOpenedModal(!openedModal);
    }, [openedModal]);

    useEffect(() => {
        setOpenedModal(iAmTeamLeader);
    }, [iAmTeamLeader]);

    if (
        !!eventResource?.ended_at ||
        !iAmTeamLeader ||
        (location?.pathname !== '/event' && location?.pathname !== '/')
    ) {
        return null;
    }

    return (
        <Modal className="modal--medium" opened={openedModal} onClose={handleModal}>
            <div className={style['teamLeader-wrapper']}>
                <div className={style['teamLeader-wrapper__image']}>
                    <img src={teamLeaderChangesImage} alt="Team Leader" />
                </div>
                <div className={style['teamLeader-wrapper__body']}>
                    <div>
                        <h2 className={style['teamLeader-wrapper__body-heading']}>
                            You are set as Team Leader!
                        </h2>
                        <p className={style['teamLeader-wrapper__body-content']}>
                            Team Name:{' '}
                            <span className={style['teamLeader-wrapper__body-content-team']}>
                                {teamData?.name}
                            </span>
                        </p>
                    </div>
                    <div>
                        <p className={style['teamLeader-wrapper__body-content']}>
                            Need help and event instructions? <br /> Click on Event Content for your
                            next steps and resources for your event.
                        </p>
                        <Button label="Got it!" onClick={handleModal} />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default React.memo(TeamLeaderChangedModal);
