import React from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import defaultLayout from 'erpcore/utils/LayoutManager/defaultLayout';
import PageLoader from 'erpcore/components/PageLoader';
import PageContent from 'erpcore/components/Layout/PageContent';
import styles from './InitialLoader.module.scss';

const InitialLoader = () => {
    return (
        <LayoutManager layout={defaultLayout} slot="main" layoutType="merge">
            <PageLoader className={styles?.['initial-loader']} />
            <PageContent>
                <p>Loading...</p>
            </PageContent>
        </LayoutManager>
    );
};

export default InitialLoader;
