import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Departments Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const Media = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'mediaobject.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Image successfully uploaded!"
                    type="success"
                    expire="3500"
                />
            );
        case 'mediaobjectversion.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Image version successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'media.customNotification':
            return (
                <Notification
                    identifier={identifier}
                    title={notificationData?.title}
                    text={notificationData?.text}
                    type={notificationData?.type}
                    expire={notificationData?.expire}
                />
            );
        case 'media.uploadFailed':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="Failed to upload file, please try again later."
                    type="error"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

Media.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

Media.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default Media;
