export const getModals = state => state.layoutManager.modals;

export const getModal = (state, id) => {
    if (id) {
        return state.layoutManager?.modals?.includes(id);
    }

    return false;
};

export const getIsSidebarConferenceOpened = state => state.layoutManager.isSidebarConferenceOpened;

export const getIsSidebarMenuOpened = state => state.layoutManager.isSidebarMenuOpened;
