import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import './Notification.scss';

class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = { expired: false, hovered: false };

        this.elementRef = React.createRef();
    }

    componentDidMount() {
        this.setTimer();
    }

    componentDidUpdate(prevProps, prevState) {
        const { expired, hovered } = this.state;
        const { prevExpired, prevHovered } = prevState;

        if (prevExpired !== expired || prevHovered !== hovered) {
            if (expired && !hovered) {
                this.removeNotification();
            }
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    setHovered(state = false) {
        this.setState({ hovered: state });
    }

    setTimer() {
        const { expire } = this.props;
        // clear any existing timer
        if (this.timer != null) {
            clearTimeout(this.timer);
        }

        // hide after `expire` milliseconds
        if (expire !== null) {
            this.timer = setTimeout(() => {
                this.timer = null;
                this.setState({ expired: true });
            }, expire);
        }
    }

    removeNotification() {
        const { dispatch, identifier, origin } = this.props;
        dispatch({
            type: notificationManagerActions.REMOVE_NOTIFICATION,
            identifier,
            origin
        });
    }

    // close on middle click
    // eslint-disable-next-line consistent-return
    removeNotificationOnAuxClick(e) {
        const { dispatch, identifier, origin } = this.props;

        if (
            identifier && // has identifier
            e?.button === 1 && // is middle click
            this.elementRef.current &&
            (this.elementRef.current.closest('.floating-notifications') ||
                this.elementRef.current.closest('.event-notifications')) && // is floating/event notification
            !['A', 'BUTTON'].includes(e?.target?.tagName) // click target is not <a> or <button>
        ) {
            e.preventDefault();
            dispatch({
                type: notificationManagerActions.REMOVE_NOTIFICATION,
                identifier,
                origin
            });
            return false;
        }
    }

    render() {
        const { identifier, type, title, text, icon, children, className } = this.props;
        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div
                ref={this.elementRef}
                className={`notification${
                    type !== '' ? ` notification--${type}` : ''
                } ${className}`}
                onMouseEnter={() => this.setHovered(true)}
                onMouseLeave={() => this.setHovered(false)}
                onMouseDown={e => this.removeNotificationOnAuxClick(e)}
            >
                {icon &&
                    (type === 'success' ||
                        type === 'warning' ||
                        type === 'error' ||
                        type === 'info' ||
                        type === 'card') && (
                        <Svg
                            className="notification__icon"
                            icon={
                                icon === true
                                    ? `notification${type[0].toUpperCase()}${type.substring(1)}`
                                    : `${icon}`
                            }
                            iconColor={type === 'card' ? 'admin' : 'sun'}
                        />
                    )}
                <div className="notification__content">
                    {title !== '' && <p className="notification__title">{title}</p>}
                    {text !== '' && <p className="notification__text">{text}</p>}
                    {children}
                </div>
                {identifier && (
                    <button
                        type="button"
                        aria-label="Close"
                        className="notification__close"
                        onClick={() => this.removeNotification()}
                    >
                        <Svg className="notification__close-icon" icon="close" />
                    </button>
                )}
            </div>
        );
    }
}

Notification.defaultProps = {
    dispatch: () => {},
    identifier: null,
    type: 'warning',
    origin: '',
    title: '',
    text: '',
    icon: false,
    children: null,
    expire: null,
    className: ''
};
Notification.propTypes = {
    dispatch: PropTypes.func,
    identifier: PropTypes.string,
    type: PropTypes.oneOf(['success', 'warning', 'error', 'info', 'pro-tip', 'card']),
    origin: PropTypes.oneOf(['', 'Mercure']),
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    expire: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string
};

export default connect()(Notification);
