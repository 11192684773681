export const actions = {
    START_EVENT: 'START_EVENT',
    START_EVENT_SUCCESSFUL: 'START_EVENT_SUCCESSUL',
    START_EVENT_FAILED: 'START_EVENT_FAILED',

    END_EVENT: 'END_EVENT',
    END_EVENT_SUCCESSFUL: 'END_EVENT_SUCCESSUL',
    END_EVENT_FAILED: 'END_EVENT_FAILED',

    COMPLETE_EVENT: 'COMPLETE_EVENT',
    COMPLETE_EVENT_SUCCESSFUL: 'COMPLETE_EVENT_SUCCESSUL',
    COMPLETE_EVENT_FAILED: 'COMPLETE_EVENT_FAILED',

    PAUSE_EVENT: 'PAUSE_EVENT',
    PAUSE_EVENT_SUCCESSFUL: 'PAUSE_EVENT_SUCCESSFUL',
    PAUSE_EVENT_FAILED: 'PAUSE_EVENT_FAILED',

    CREATE_NEW_TEAM: 'CREATE_NEW_TEAM',
    START_RESHUFFLE_TEAMS: 'START_RESHUFFLE_TEAMS',
    RESHUFFLE_TEAMS_SUCCESSFUL: 'RESHUFFLE_TEAMS_SUCCESSFUL',
    RESHUFFLE_TEAMS_FAILED: 'RESHUFFLE_TEAMS_FAILED',

    START_FETCHING_COMPLETED_CHALLENGES: 'START_FETCHING_COMPLETED_CHALLENGES',
    FETCHING_SUCCESSFUL_COMPLETED_CHALLENGES: 'FETCHING_SUCCESSFUL_COMPLETED_CHALLENGES',
    FETCHING_FAILED_COMPLETED_CHALLENGES: 'FETCHING_FAILED_COMPLETED_CHALLENGES',

    START_FETCHING_TEAM_COMPLETED_CHALLENGES: 'START_FETCHING_TEAM_COMPLETED_CHALLENGES',
    FETCHING_SUCCESSFUL_TEAM_COMPLETED_CHALLENGES: 'FETCHING_SUCCESSFUL_TEAM_COMPLETED_CHALLENGES',
    FETCHING_FAILED_TEAM_COMPLETED_CHALLENGES: 'FETCHING_FAILED_TEAM_COMPLETED_CHALLENGES',
    ADD_TEAM_COMPLETED_CHALLENGES: 'ADD_TEAM_COMPLETED_CHALLENGES',

    START_UPDATE_COMPLETED_CHALLENGE: 'START_UPDATE_COMPLETED_CHALLENGE',
    UPDATE_SUCCESSFUL_COMPLETED_CHALLENGE: 'UPDATE_SUCCESSFUL_COMPLETED_CHALLENGE',
    UPDATE_FAILED_COMPLETED_CHALLENGE: 'UPDATE_FAILED_COMPLETED_CHALLENGE',
    ADD_COMPLETED_CHALLENGE: 'ADD_COMPLETED_CHALLENGE',

    EVENT_CHALLENGE_WITH_VIDEO_ACTIVE: 'EVENT_CHALLENGE_WITH_VIDEO_ACTIVE',
    EVENT_CHALLENGE_WITH_VIDEO_INACTIVE: 'EVENT_CHALLENGE_WITH_VIDEO_INACTIVE',

    START_EVENT_CHALLENGE: 'START_EVENT_CHALLENGE'
};

export const initialState = {
    eventStatusChange: false,
    isReshuffling: false,
    eventTeamCompletedChallenges: [],
    eventTeamCompletedChallengesFetching: false,
    eventCompletedChallenges: [],
    eventCompletedChallengesFetching: false,
    isEventChallengeWithVideoActive: false
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_COMPLETED_CHALLENGES: {
            return {
                ...state,
                eventCompletedChallengesFetching: true
            };
        }
        case actions.START_FETCHING_TEAM_COMPLETED_CHALLENGES: {
            return {
                ...state,
                eventTeamCompletedChallengesFetching: true
            };
        }
        case actions.EVENT_CHALLENGE_WITH_VIDEO_ACTIVE: {
            return {
                ...state,
                isEventChallengeWithVideoActive: true
            };
        }
        case actions.EVENT_CHALLENGE_WITH_VIDEO_INACTIVE: {
            return {
                ...state,
                isEventChallengeWithVideoActive: false
            };
        }
        case actions.FETCHING_SUCCESSFUL_COMPLETED_CHALLENGES: {
            return {
                ...state,
                eventCompletedChallengesFetching: false,
                eventCompletedChallenges: response?.map(item => item?.id) || []
            };
        }
        case actions.FETCHING_SUCCESSFUL_TEAM_COMPLETED_CHALLENGES: {
            return {
                ...state,
                eventTeamCompletedChallengesFetching: false,
                eventTeamCompletedChallenges: response
            };
        }
        case actions.ADD_TEAM_COMPLETED_CHALLENGES: {
            let newEventTeamCompletedChallenges = [];

            const itemExists = (state?.eventTeamCompletedChallenges || []).find(
                item => response?.id && response?.id === item?.id
            );

            if (itemExists) {
                newEventTeamCompletedChallenges = (
                    state?.eventTeamCompletedChallenges || []
                ).reduce((accumulator, currentValue) => {
                    if (response?.id && response?.id === currentValue?.id) {
                        accumulator = [...accumulator, response];
                    } else {
                        accumulator = [...accumulator, currentValue];
                    }
                    return accumulator;
                }, []);
            } else {
                newEventTeamCompletedChallenges = [
                    ...(state?.eventTeamCompletedChallenges || []),
                    response
                ];
            }

            return {
                ...state,
                eventTeamCompletedChallengesFetching: false,
                eventTeamCompletedChallenges: newEventTeamCompletedChallenges
            };
        }
        case actions.ADD_COMPLETED_CHALLENGE: {
            return {
                ...state,
                eventCompletedChallengesFetching: false,
                eventCompletedChallenges: [response, ...(state?.eventCompletedChallenges || [])]
            };
        }
        case actions.FETCHING_FAILED_COMPLETED_CHALLENGES: {
            return {
                ...state,
                eventCompletedChallengesFetching: false
            };
        }
        case actions.FETCHING_FAILED_TEAM_COMPLETED_CHALLENGES: {
            return {
                ...state,
                eventTeamCompletedChallengesFetching: false
            };
        }
        case actions.START_EVENT: {
            return {
                ...state,
                eventStatusChange: true
            };
        }
        case actions.START_EVENT_SUCCESSFUL: {
            return {
                ...state,
                eventStatusChange: false
            };
        }
        case actions.START_EVENT_FAILED: {
            return {
                ...state,
                eventStatusChange: false
            };
        }
        case actions.END_EVENT: {
            return {
                ...state,
                eventStatusChange: true
            };
        }
        case actions.END_EVENT_SUCCESSFUL: {
            return {
                ...state,
                eventStatusChange: false
            };
        }
        case actions.END_EVENT_FAILED: {
            return {
                ...state,
                eventStatusChange: false
            };
        }
        case actions.PAUSE_EVENT: {
            return {
                ...state,
                eventStatusChange: true
            };
        }
        case actions.PAUSE_EVENT_SUCCESSFUL: {
            return {
                ...state,
                eventStatusChange: false
            };
        }
        case actions.PAUSE_EVENT_FAILED: {
            return {
                ...state,
                eventStatusChange: false
            };
        }
        case actions.START_RESHUFFLE_TEAMS: {
            return {
                ...state,
                isReshuffling: true
            };
        }
        case actions.RESHUFFLE_TEAMS_SUCCESSFUL: {
            return {
                ...state,
                isReshuffling: false
            };
        }
        case actions.RESHUFFLE_TEAMS_FAILED: {
            return {
                ...state,
                isReshuffling: false
            };
        }
        default:
            return state;
    }
};
