import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setMercureAuthCookie } from 'erpcore/api/restClient';
import { getResourceByID } from 'erpcore/utils/ResourceManager/ResourceManager.selectors';
import { actions as resourceActions } from 'erpcore/utils/ResourceManager/ResourceManager.reducer';
import enviromentVariables from 'erpcore/utils/enviromentVariables';
import { actions as eventActions } from 'erpcore/screens/Event/Event.reducer';
import { useHistory, useLocation } from 'react-router-dom';
import useIsJoinScreen from 'erpcore/utils/useIsJoinScreen';
import {
    getIsErpUserDataRetrieved,
    isUserAdmin
} from 'erpcore/utils/ErpUserManager/ErpUserManager.selectors';
import {
    getActiveEvent,
    getActiveTeam,
    getActiveUser
} from 'erpcore/utils/AuthManager/AuthManager.selectors';
import dto, { getIdFromIri } from '../dto';

const ManageMercureEventSourceSubscriptions = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const isErpUserDataRetrieved = useSelector(getIsErpUserDataRetrieved);
    const isAdmin = useSelector(isUserAdmin);
    const activeUser = useSelector(getActiveUser);
    const activeUserRef = useRef(activeUser);
    const activeEvent = useSelector(getActiveEvent);
    const isJoinScreenActive = useIsJoinScreen();
    const userResource = useSelector(state => getResourceByID(state, activeUser?.id));
    const activeTeam = useSelector(getActiveTeam);
    const teamID = isAdmin
        ? getIdFromIri(activeTeam?.id)
        : getIdFromIri(userResource?.event_team?.id);

    const defaultSourceConfig = { topic: null, source: null, jwt: null };
    const eventSourceUser = useRef({ ...defaultSourceConfig });
    const eventSourceEvent = useRef({ ...defaultSourceConfig });
    const eventSourceTeam = useRef({ ...defaultSourceConfig });
    const eventSourceTeamNotifications = useRef({ ...defaultSourceConfig });

    const [jwtToken, setJwtToken] = useState(null);
    const jwtTokenRef = useRef(jwtToken);

    const [
        mercureAuthCookieIsSetForThisJwtToken,
        setMercureAuthCookieIsSetForThisJwtToken
    ] = useState(null);

    const isMounted = useRef(true);
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const getSubscriptionRefSource = ref => {
        return ref?.current?.source || null;
    };

    const getSubscriptionRefTopic = ref => {
        return ref?.current?.topic || null;
    };

    const getSubscriptionRefJwt = ref => {
        return ref?.current?.jwt || null;
    };

    const resetSubscriptionRef = ref => {
        ref.current = { ...defaultSourceConfig };
    };

    const updateSubscriptionRef = (ref, data = null) => {
        ref.current = { ...ref?.current, ...data };
    };

    const unsubscribeMercure = useCallback(ref => {
        try {
            const source = getSubscriptionRefSource(ref);
            if (source) {
                source.close();
                resetSubscriptionRef(source);
            }
        } catch (e) {
            //
        }
    }, []);

    const initiateMercureConnection = useCallback(url => {
        return new EventSource(url, { withCredentials: true });
    }, []);

    const subscribeMercure = useCallback(({ ref, topic }) => {
        if (!ref || !topic) {
            return null;
        }

        const oldTopic = getSubscriptionRefTopic(ref);

        if (oldTopic === topic) {
            unsubscribeMercure(ref);
        }

        let newEventSource = null;

        const attachOnMessageListener = eventSource => {
            eventSource.onopen = event => {
                dispatch({ type: resourceActions.REMOVE_MERCURE_ERROR, id: event?.target?.url });
            };

            // eslint-disable-next-line consistent-return
            eventSource.onmessage = event => {
                // state is not available here
                const data = JSON.parse(event?.data);

                if (data?.ping) {
                    return false;
                }

                if (topic?.includes('event-teams') && data?.data?.type === 'CompletedChallenge') {
                    dispatch({
                        type: eventActions.ADD_TEAM_COMPLETED_CHALLENGES,
                        response: dto(data, true)
                    });
                }

                // sign out user (id as iri)
                if (data?.extra?.signout && data?.extra?.signout === activeUserRef?.current?.id) {
                    history.push('/sign-out');
                }
                // kick out user (id as iri)
                else if (
                    data?.extra?.kickout &&
                    data?.extra?.kickout === activeUserRef?.current?.id
                ) {
                    history.push('/kick-out');
                }
                // send notification
                else if (!data?.extra?.signout && !data?.extra?.kickout) {
                    dispatch({
                        type: resourceActions.STORE_RESOURCE,
                        response: JSON.parse(event?.data),
                        ignoreIncluded: true
                    });
                }
            };
        };

        const attachOnErrorListener = eventSource => {
            // handle error
            eventSource.onerror = event => {
                dispatch({ type: resourceActions.SET_MERCURE_ERROR, id: event?.target?.url });
            };
        };

        /*
        // TODO: on error; fetch events via api
        const attachOnErrorListener = eventSource => {
            // handle error (reconnect)
            eventSource.onerror = event => {
                const url = event?.target?.url;
                if (url) {
                    // close errored connection
                    event.target.close();

                    // established new connection
                    setTimeout(() => {
                        if (isMounted?.current) {
                            newEventSource = initiateMercureConnection(url);
                            attachOnMessageListener(newEventSource);
                            attachOnErrorListener(newEventSource);
                        }
                    }, 1000);
                }
            };
        };
        */

        newEventSource = initiateMercureConnection(
            `${enviromentVariables?.MERCURE_ENDPOINT}?topic=${topic}`
        );

        attachOnMessageListener(newEventSource);
        attachOnErrorListener(newEventSource);

        updateSubscriptionRef(ref, { topic, source: newEventSource });

        return null;
    }, []);

    const setAuthCookie = useCallback(token => {
        setMercureAuthCookie(token)
            .then(() => {
                setMercureAuthCookieIsSetForThisJwtToken(token);
            })
            .catch(() => {
                setMercureAuthCookieIsSetForThisJwtToken(token);
            });
    }, []);

    /**
     * sync activeUser state with ref
     */
    useEffect(() => {
        activeUserRef.current = activeUser;
    }, [activeUser]);

    /**
     * sync jwtToken state with ref
     */
    useEffect(() => {
        jwtTokenRef.current = jwtToken;
    }, [jwtToken]);

    /**
     * set JWT token
     */
    useEffect(() => {
        if (isErpUserDataRetrieved && activeUser) {
            const token = isAdmin ? localStorage.getItem('token') : activeUser?.jwt;
            if (token) {
                setJwtToken(token);
            }
        }
    }, [isErpUserDataRetrieved, activeUser, isAdmin]);

    /**
     * Set JWT token cookie for Mercure domain
     */
    useEffect(() => {
        if (jwtToken) {
            setAuthCookie(jwtToken);
        }
    }, [jwtToken]);

    const manageSubscription = useCallback(({ ref, allowedToSubscribe, topic, jwt }) => {
        const isSubscribed = !!getSubscriptionRefSource(ref) || !!getSubscriptionRefTopic(ref);

        // is subscribed but shouldn't be
        if (!allowedToSubscribe && isSubscribed) {
            unsubscribeMercure(ref);
        }

        // is allowed to be subscribed
        if (allowedToSubscribe) {
            // isn't subscribed
            if (!isSubscribed) {
                subscribeMercure({ ref, topic, jwt });
            }

            if (isSubscribed) {
                // is subscribed to wrong topic or with old jwt
                const currentSubscriptionTopic = getSubscriptionRefTopic(ref);
                const currentSubscriptionJwt = getSubscriptionRefJwt(ref);
                if (topic !== currentSubscriptionTopic || jwt !== currentSubscriptionJwt) {
                    unsubscribeMercure(ref);
                    subscribeMercure({ ref, topic, jwt });
                }
            }
        }
    }, []);

    /**
     * Subscribe: user notifications
     */
    useEffect(() => {
        manageSubscription({
            ref: eventSourceUser,
            allowedToSubscribe:
                isErpUserDataRetrieved &&
                activeUser?.id &&
                jwtToken &&
                jwtToken === mercureAuthCookieIsSetForThisJwtToken &&
                !isJoinScreenActive &&
                !!activeEvent?.id,
            topic: isAdmin
                ? `${activeUser?.id}/events/${getIdFromIri(activeEvent?.id)}`
                : activeUser?.id,
            jwt: jwtToken
        });
    }, [
        isErpUserDataRetrieved,
        activeUser,
        isJoinScreenActive,
        jwtToken,
        mercureAuthCookieIsSetForThisJwtToken,
        activeEvent?.id
    ]);

    /**
     * Subscribe: event notifications
     */
    useEffect(() => {
        manageSubscription({
            ref: eventSourceEvent,
            allowedToSubscribe:
                isErpUserDataRetrieved &&
                activeUser?.id &&
                activeEvent?.id &&
                jwtToken &&
                jwtToken === mercureAuthCookieIsSetForThisJwtToken &&
                !isJoinScreenActive,
            topic: `${activeEvent?.id}/notifications`,
            jwt: jwtToken
        });
    }, [
        isErpUserDataRetrieved,
        activeUser,
        isJoinScreenActive,
        jwtToken,
        mercureAuthCookieIsSetForThisJwtToken,
        activeEvent
    ]);

    /**
     * Subscribe: team notifications
     */
    useEffect(() => {
        manageSubscription({
            ref: eventSourceTeamNotifications,
            allowedToSubscribe:
                isErpUserDataRetrieved &&
                activeUser?.id &&
                activeEvent?.id &&
                teamID &&
                jwtToken &&
                jwtToken === mercureAuthCookieIsSetForThisJwtToken &&
                !isJoinScreenActive,
            topic: `${activeEvent?.id}/${teamID}/notifications`,
            jwt: jwtToken
        });
    }, [
        isErpUserDataRetrieved,
        activeUser,
        isJoinScreenActive,
        jwtToken,
        mercureAuthCookieIsSetForThisJwtToken,
        activeEvent,
        teamID
    ]);

    /**
     * Subscribe: team notifications
     */
    useEffect(() => {
        manageSubscription({
            ref: eventSourceTeam,
            allowedToSubscribe:
                isErpUserDataRetrieved &&
                activeUser?.id &&
                activeEvent?.id &&
                location?.pathname?.includes('/event') &&
                teamID &&
                jwtToken &&
                jwtToken === mercureAuthCookieIsSetForThisJwtToken &&
                !isJoinScreenActive,
            topic: `/api/event-teams/${teamID}`,
            jwt: jwtToken
        });
    }, [
        location,
        isErpUserDataRetrieved,
        activeUser,
        isJoinScreenActive,
        jwtToken,
        mercureAuthCookieIsSetForThisJwtToken,
        activeEvent,
        teamID
    ]);

    return null;
};

export default ManageMercureEventSourceSubscriptions;
