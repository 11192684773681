import { insertIf } from 'erpcore/utils/utils';

export const actions = {
    OPEN_MODAL: 'OPEN_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',
    TOGGLE_MODAL: 'TOGGLE_MODAL',
    TOGGLE_SIDEBAR_CONFERENCE: 'TOGGLE_SIDEBAR_CONFERENCE',
    TOGGLE_SIDEBAR_MENU: 'TOGGLE_SIDEBAR_MENU'
};

export const initialState = {
    modals: [],
    isSidebarConferenceOpened: false,
    isSidebarMenuOpened: false
};

const addModal = (state, id) => {
    return [...(state?.modals || []), id];
};

const removeModal = (state, id) => {
    return state?.modals?.filter(item => {
        if (Array.isArray(id)) {
            return !id.includes(item);
        }
        return item !== id;
    });
};

const cleanupModalCollection = ['EventGuidelines', 'NotificationsLog']; // This code is too far away from source, it could be improved by moving to redux state

const layoutStateCleanup = (state, source) => {
    const cleanUpFunctions = {
        sidebar: () => {
            return { isSidebarConferenceOpened: false };
        },
        sidebarMenu: () => {
            return { isSidebarMenuOpened: false };
        },
        modals: () => {
            if (cleanupModalCollection.some(item => (state?.modals || []).includes(item))) {
                return { modals: removeModal(state, cleanupModalCollection) };
            }
            return null;
        }
    };

    return Object.keys(cleanUpFunctions).reduce((accumulator, sourceKey) => {
        if (sourceKey !== source) {
            cleanUpFunctions[sourceKey]();
            return { ...accumulator, ...cleanUpFunctions[sourceKey]() };
        }
        return { ...accumulator };
    }, {});
};

export default (state = initialState, { type, id, newState }) => {
    switch (type) {
        case actions.OPEN_MODAL: {
            return {
                ...state,
                ...insertIf(
                    cleanupModalCollection.includes(id),
                    layoutStateCleanup(state, 'modals')
                ),
                modals: addModal(state, id)
            };
        }
        case actions.CLOSE_MODAL: {
            return {
                ...state,
                modals: removeModal(state, id)
            };
        }
        case actions.TOGGLE_MODAL: {
            const shouldAddModal = !state.modals.includes(id);
            return {
                ...state,
                ...insertIf(
                    shouldAddModal && cleanupModalCollection.includes(id),
                    layoutStateCleanup(state, 'modals')
                ),
                modals: shouldAddModal ? addModal(state, id) : removeModal(state, id)
            };
        }
        case actions.TOGGLE_SIDEBAR_CONFERENCE: {
            return {
                ...state,
                ...insertIf(newState, layoutStateCleanup(state, 'sidebar')),
                isSidebarConferenceOpened: newState
            };
        }
        case actions.TOGGLE_SIDEBAR_MENU: {
            return {
                ...state,
                ...insertIf(newState, layoutStateCleanup(state, 'sidebarMenu')),
                isSidebarMenuOpened: newState
            };
        }
        default:
            return state;
    }
};
